import React, {FC, useState} from 'react';
import {useCustomMutation} from '@pankod/refine-core';
import {
    EModerationStatus,
    IBanButtonProps,
    IModerateButtonProps,
    IModerateEliteButtonProps,
    ISalon
} from '../../common/types';
import {settings} from '../../common/settings';
import {Button, Modal} from '@pankod/refine-antd';
import {LockOutlined, UnlockOutlined} from '@ant-design/icons';
import {Space} from 'antd';
import {useModal} from "@refinedev/antd";

const ModerateEliteButton: FC<IModerateEliteButtonProps> = ({ onSuccess, id,isAccept, eliteStatus }) => {
    const {mutate} = useCustomMutation<ISalon>()
    const { modalProps, show, close } = useModal();

    const handleOk = () => {
        if (id) {
            handleModerate(id, modalValue);
        }
        close();
    };
    const [modalTitle, setModalTitle] = useState("Подтверждение");
    const [modalValue, setModalValue] = useState(false);

    const handleShowModal = (title: string, value:boolean) => {
        setModalTitle(title);
        setModalValue(value);
        show();
    };

    const handleModerate = (id: string, value: boolean) => mutate({
        url: `${settings.api.url}/users/performers/elite/${id}/${value ? 'accept' : 'decline'}`,
        method: 'patch',
        values: {}
    }, {
        onSuccess: onSuccess
    })

    return (
        <Space>
            <Button
                type="primary"
                disabled={eliteStatus === EModerationStatus.ACCEPTED || eliteStatus === EModerationStatus.NOTMODERATED || eliteStatus === EModerationStatus.DECLINED}

                onClick={()=>handleShowModal("Подтвердите присвоение статуса элиты ", true)}
            >
                Одобрить заявку
            </Button>
            <Modal
                {...modalProps}
                title={modalTitle}
                onOk={handleOk }
                onCancel={close}
                okText={"Подтвердить"}
                cancelText={"Отмена"}
            >
            </Modal>
            <Button onClick={()=>handleShowModal("Подтвердите отклонение заявки на статус элиты ", false)} disabled={eliteStatus === EModerationStatus.NOTMODERATED || eliteStatus === EModerationStatus.DECLINED || eliteStatus === EModerationStatus.ACCEPTED}>
                Отклонить заявку
            </Button>
            <Button onClick={()=>handleShowModal("Подтвердите отклонение статуса элиты ", false)} disabled={eliteStatus === EModerationStatus.NOTMODERATED || eliteStatus === EModerationStatus.SENT || eliteStatus === EModerationStatus.DECLINED}>
                Отклонить статус
            </Button>
        </Space>
    )
}

export default ModerateEliteButton
