import { FC, useEffect } from "react";
import { DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";

import { setInitialFilters } from "../../common/functions";

import { Filter } from "./Filter";

import { IFilter } from "../../common/types";

export const ProfitFilter: FC<IFilter> = ({
  formProps,
  filters,
  setClearFilters,
}) => {
  useEffect(() => {
    setInitialFilters(formProps, filters);
    const timeslots = filters
      ?.filter((t) => "field" in t && t.field === "createdAt")
      ?.map((t) => dayjs(t.value));
    if (timeslots) {
      formProps.form?.setFieldValue("createdAt", timeslots);
    }
  }, []);

  return (
    <Filter
      formProps={formProps}
      setClearFilters={setClearFilters}
      filters={filters}
    >
      <Form.Item label="Дата" name="createdAt">
        <DatePicker.RangePicker
          placeholder={["Начало", "Конец"]}
          allowClear={true}
        />
      </Form.Item>
      <Form.Item label="Никнейм" name="search">
        <Input placeholder="Никнейм" />
      </Form.Item>
    </Filter>
  );
};
