import { ClientList, ClientShow } from "../pages/clients";
import {
  AuditOutlined,
  DollarOutlined,
  LockOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { PerformerList, PerformerShow } from "../pages/performers";
import { OrderList, OrderShow } from "../pages/orders";
import { DisputeList, DisputeShow } from "../pages/disputes";
import { UserSupportList } from "../pages/userSupport";
import { ProfitList } from "pages/profit/list";
import { ProfitShow } from "pages/profit/show";
import { CryptocurrencyList, CryptocurrencyShow } from "pages/cryptocurrency";
import { BalanceList, BalanceShow } from "pages/balance";

export const resources = [
  {
    name: "clients",
    options: {
      label: "Клиенты",
    },
    list: ClientList,
    show: ClientShow,
  },
  {
    name: "performers",
    options: {
      label: "Сотрудники",
    },
    icon: <UserOutlined />,
    list: PerformerList,
    show: PerformerShow,
  },
  {
    name: "orders",
    options: {
      label: "Сеансы",
    },
    icon: <ShoppingCartOutlined />,
    list: OrderList,
    show: OrderShow,
  },
  {
    name: "disputes",
    options: {
      label: "Диспуты",
    },
    icon: <AuditOutlined />,
    list: DisputeList,
    show: DisputeShow,
  },
  {
    name: "cryptocurrency",
    options: {
      label: "Криптовалюта",
    },
    icon: <DollarOutlined />,
    list: CryptocurrencyList,
    show: CryptocurrencyShow,
  },
  {
    name: "balance",
    options: {
      label: "По балансу",
    },
    icon: <DollarOutlined />,
    list: BalanceList,
    show: BalanceShow,
  },
  {
    name: "profit",
    options: {
      label: "Прибыль",
    },
    icon: <DollarOutlined />,
    list: ProfitList,
    show: ProfitShow,
  },
  {
    name: "user-support",
    options: {
      label: "Техподдержка",
    },
    icon: <LockOutlined />,
    list: UserSupportList,
  },
];
