import {Refine} from "@pankod/refine-core";
import {ConfigProvider} from "@pankod/refine-antd";
import {RefineKbar, RefineKbarProvider} from "@refinedev/kbar";

import {
  notificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerProvider from "@pankod/refine-react-router-v6";
import {
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import "styles/antd.less";
import {LoginPage} from "pages/login";
import {BrowserRouter} from "react-router-dom";
import {authProvider} from "./providers/authProvider";
import {ColorModeContextProvider} from "./contexts/color-mode";
import {settings} from "./common/settings";
import {
    UserProvider,
    CustomServer,
    FinanceProvider,
    OrderProvider,
    SupportMessageProvider, SupportChatProvider, CommissionProvider
} from "./providers/dataProvider";
import {Footer, Header, Layout, OffLayoutArea, Sider, Title} from "./components/layout";
import {axiosInstance} from './api/instance';
import {useTranslation} from 'react-i18next';
import ru from 'antd/locale/ru_RU'
import 'moment/locale/ru';
import moment from 'moment';
import {resources} from './common/resources';
moment.locale('ru');

function App() {
    const {t, i18n} = useTranslation()
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage('ru'),
    getLocale: () => 'ru',
  }
  return (
    <BrowserRouter>
      <ConfigProvider locale={ru}>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <Refine
              dataProvider={{
                default: dataProvider(settings.api.url, axiosInstance),
                customProvider: CustomServer(settings.api.url, axiosInstance),
                userProvider: UserProvider(settings.api.url, axiosInstance),
                financeProvider: FinanceProvider(settings.api.url, axiosInstance),
                orderProvider: OrderProvider(settings.api.url, axiosInstance),
                supportMessageProvider: SupportMessageProvider(settings.api.url, axiosInstance),
                supportChatProvider: SupportChatProvider(settings.api.url, axiosInstance),
                  commissionProvider: CommissionProvider(settings.api.url, axiosInstance),
              }}
              i18nProvider={i18nProvider}
              notificationProvider={notificationProvider}
              routerProvider={routerProvider}
              authProvider={authProvider}
              LoginPage={LoginPage}
              Title={Title}
              Header={Header}
              Sider={Sider}
              Footer={Footer}
              Layout={Layout}
              OffLayoutArea={OffLayoutArea}
              resources={resources}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <RefineKbar/>
              <UnsavedChangesNotifier/>
            </Refine>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App
