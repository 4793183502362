import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { getDefaultSortOrder } from "@refinedev/antd";
import moment from "moment";
import { Link } from "react-router-dom";

import { ProfitFilter } from "components/filters/ProfitFilter";

import { DATE_TIME_FORMAT } from "../../common/constants";
import { addressShort } from "common/functions";

import { BALANCE_TYPE, EBalanceType, IBalance } from "../../common/types";

export const BalanceList = () => {
  const { tableProps, filters, searchFormProps, sorter } = useTable<
    IBalance,
    HttpError
  >({
    resource: "balance",
    dataProviderName: "financeProvider",
    syncWithLocation: true,
    hasPagination: true,
    initialPageSize: 10,
    errorNotification: {
      type: "error",
      description: "Ошибка",
      message: "Не удалось получить список прибыли",
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { search, createdAt }: any = params;
      filters.push({
        field: "search",
        operator: "eq",
        value: search,
      });
      filters.push({
        field: "createdAt",
        // @ts-ignore
        operator: "btw",
        value:
          createdAt &&
          createdAt[0].format("YYYY-MM-DD") +
            "," +
            createdAt[1].format("YYYY-MM-DD"),
      });
      return filters;
    },
  });

  const positiveSum = (type: EBalanceType) => {
    return (
      type === EBalanceType.refill ||
      type === EBalanceType.payout ||
      type === EBalanceType.return
    );
  };

  return (
    <Row
      gutter={[16, 16]}
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <Col lg={18} xs={24}>
        <Card title="По балансу">
          <ProfitFilter formProps={searchFormProps} filters={filters} />

          <Table
            {...tableProps}
            rowKey="id"
            pagination={{
              ...tableProps.pagination,
              pageSize: 10,
              position: ["bottomLeft"],
              style: { marginBottom: 0 },
              hideOnSinglePage: true,
            }}
          >
            <Table.Column
              dataIndex="nickname"
              key="nickname"
              title="Никнейм"
              render={(_: unknown, record: IBalance) =>
                record?.nickname ? (
                  <Link
                    to={
                      record.userType === "CLIENT"
                        ? `/clients/show/${record.userId}`
                        : `/performers/show/${record.userId}`
                    }
                    target="_blank"
                  >
                    {record?.nickname}
                  </Link>
                ) : (
                  "-"
                )
              }
            />

            <Table.Column
              dataIndex="amount"
              key="amount"
              title="Сумма операции"
              render={(amount: number, record: IBalance) =>
                amount ? (
                  <span
                    style={
                      positiveSum(record.type)
                        ? { color: "#79F093" }
                        : { color: "#F25A45" }
                    }
                  >
                    {`${positiveSum(record.type) ? "+" : "-"} ${parseFloat(
                      amount.toFixed(2)
                    )} $`}
                  </span>
                ) : (
                  "-"
                )
              }
            />

            <Table.Column
              dataIndex="type"
              key="type"
              title="Тип"
              render={(type: string, record: IBalance) => (
                <>
                  {type
                    ? `${BALANCE_TYPE[type as EBalanceType]}${
                        record.info ? ":" : ""
                      } ${
                        type === EBalanceType.seance_commission ||
                        type === EBalanceType.seance_fee
                          ? `(${record.percentage}%)`
                          : ""
                      } ${
                        type === EBalanceType.boost_buy ||
                        type === EBalanceType.shift
                          ? `(${record.percentage} шт.)`
                          : ""
                      }`
                    : "-"}
                  {record.info ? (
                    type === EBalanceType.withdrawal ||
                    type === EBalanceType.refill ? (
                      <Link
                        to={`https://tronscan.org/#/transaction/${record.info}`}
                        target="_blank"
                      >
                        {addressShort(record.info, 3.5, 5)}
                      </Link>
                    ) : (
                      <Link to={`/orders/show/${record?.info}`} target="_blank">
                        №{record.info.slice(0, 8)}
                      </Link>
                    )
                  ) : (
                    ""
                  )}
                </>
              )}
            />

            <Table.Column
              dataIndex="balance"
              key="balance"
              title="Баланс"
              render={(balance: number) =>
                balance ? `${parseFloat(balance.toFixed(2))} $` : "-"
              }
            />

            <Table.Column
              dataIndex="createdAt"
              key="createdAt"
              title="Дата"
              defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
              sorter
              render={(createdAt) =>
                createdAt ? moment(createdAt).format(DATE_TIME_FORMAT) : "-"
              }
            />
          </Table>

          <Space style={{ marginTop: 20 }}>
            <Button
              htmlType="submit"
              type="default"
              onClick={() =>
                (window.location.href =
                  "https://api.uwu.dev.pyrobyte.ru/v1/admin/payment-history/csv")
              }
            >
              Экспортировать CSV
            </Button>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};
