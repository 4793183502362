import React, {FC, useState} from 'react';
import {useCustomMutation} from '@pankod/refine-core';
import {IBanButtonProps, ISalon} from '../../common/types';
import {settings} from '../../common/settings';
import {Button} from '@pankod/refine-antd';
import {LockOutlined, UnlockOutlined} from '@ant-design/icons';

const BanUserButton: FC<IBanButtonProps> = ({ onSuccess, id, isBlocked, isGhost, section }) => {
  const {mutate} = useCustomMutation<ISalon>()
  const [loading, setLoading] = useState(false)

  const block = (id: string, isBlocked: boolean) => mutate({
    url: `${settings.api.url}/users/${section}/${id}/${isBlocked ? 'un' : ''}block`,
    method: 'patch',
    values: {}
  }, {
    onSuccess: onSuccess,
    onSettled: () => {
      setLoading(false)
    }
  })

  const handleBlock = (id: string, isBlocked: boolean) => {
    setLoading(true)
    block(id, isBlocked)
  }

  return (
    <Button
      loading={loading}
      type={isGhost ? 'text' : 'default'}
      size={isGhost ? 'small' : 'middle'}
      className={isGhost ? 'action-table-button' : ''}
      onClick={() => id ? handleBlock(id, isBlocked) : null}
      icon={isBlocked ? <UnlockOutlined /> : <LockOutlined />}
    >
      {isBlocked ? 'Раз' : 'За'}блокировать
    </Button>
  )
}

export default BanUserButton
