import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Show,
  Switch,
  Input,
} from "@pankod/refine-antd";
import { Link } from "react-router-dom";
import { useCustomMutation, useOne, useResource } from "@pankod/refine-core";
import { Form, Select, Space } from "antd";
import { StarFilled } from "@ant-design/icons";

import { DISPUTE_STATUS, FORM_REQUIRED_RULE } from "../../common/constants";
import { setDocumentTitle } from "../../common/functions";
import { settings } from "../../common/settings";

import { DISPUTE_TYPE, EDisputeStatus, IDispute } from "../../common/types";
import Loader from "components/layout/Loader";

export const DisputeShow = () => {
  const { mutate } = useCustomMutation<IDispute>();
  const { id } = useResource();
  const [dispute, setDispute] = useState<IDispute | null>(null);
  const [form] = Form.useForm();
  const disputeStatus = Form.useWatch("disputeStatus", form);
  const isOpenDispute = dispute?.status === EDisputeStatus.NEW;

  const {
    data: response,
    refetch,
    isLoading,
  } = useOne<IDispute>({
    resource: "disputes",
    dataProviderName: "orderProvider",
    id: id || "",
    errorNotification: {
      type: "error",
      description: "Ошибка",
      message: "Не удалось получить информацию о диспуте",
    },
  });

  const handleResolution = () => {
    const values = form.getFieldsValue();
    mutate(
      {
        url: `${settings.api.url}/users/orders/disputes/${id}/resolution`,
        method: "post",
        values,
      },
      {
        onSuccess: async () => {
          await refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (response) {
      setDispute(response.data);
      setDocumentTitle(
        `Диспут к сеансу ${
          dispute?.seanceId ? `№${dispute?.seanceId.slice(0, 8)}` : ""
        }`
      );
    }
  }, [response]);

  return (
    <Show
      canDelete={false}
      breadcrumb={false}
      headerProps={{
        title: `Диспут к сеансу ${
          dispute?.seanceId ? `№${dispute?.seanceId.slice(0, 8)}` : ""
        }`,
      }}
      headerButtons={<span />}
    >
      <Row gutter={[16, 16]}>
        <Col
          span={6}
          xs={24}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isLoading && dispute?.seanceId ? (
            <div style={{ position: "relative", bottom: 0, right: "130px" }}>
              <Link to={`/orders/show/${dispute?.orderId}`} target="_blank">
                Сеанс №${dispute?.seanceId.slice(0, 8)}
              </Link>
            </div>
          ) : (
            ""
          )}
          {!isLoading ? (
            <Card title="Действия" className="card-dispute">
              <Form form={form} layout="vertical" onFinish={handleResolution}>
                <Form.Item
                  label="Статус"
                  name="disputeStatus"
                  rules={FORM_REQUIRED_RULE}
                >
                  {!isOpenDispute ? (
                    <span>
                      {DISPUTE_TYPE[dispute?.status as EDisputeStatus]}
                    </span>
                  ) : (
                    <Select
                      style={{ maxWidth: 303 }}
                      placeholder="Выберите статус"
                    >
                      {DISPUTE_STATUS.filter((ds) =>
                        [
                          EDisputeStatus.CLIENTFAVOR,
                          EDisputeStatus.ADVERTISERFAVOR,
                        ].includes(ds.key)
                      ).map((ds) => (
                        <Select.Option key={ds.key} value={ds.key}>
                          {ds.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                {!isOpenDispute && (
                  <div style={{ marginBottom: 16 }}>
                    <h2 style={{ fontSize: 14, fontWeight: 700 }}>
                      Возврат средств
                    </h2>
                    <span>
                      {parseFloat(
                        Number(dispute?.resolution?.refundAmount).toFixed(2)
                      )}{" "}
                      $
                    </span>
                  </div>
                )}

                {isOpenDispute &&
                  disputeStatus === EDisputeStatus.CLIENTFAVOR && (
                    <Form.Item>
                      {isOpenDispute ? (
                        <h2 style={{ fontSize: 14, fontWeight: 700 }}>
                          Вернуть клиенту
                        </h2>
                      ) : null}
                      {isOpenDispute ? (
                        <p style={{ fontSize: 10, fontWeight: 700 }}>
                          Сумма сеанса {dispute?.seanceCost} $
                        </p>
                      ) : null}
                      {isOpenDispute ? (
                        <Space className="sum-space-dispute">
                          <Form.Item name="refundAmount">
                            <Input type="number" style={{ maxWidth: 120 }} />
                          </Form.Item>

                          <Space style={{ marginBottom: 25, height: 32 }}>
                            <Button
                              type="primary"
                              onClick={() =>
                                form.setFieldValue(
                                  "refundAmount",
                                  dispute?.seanceCost &&
                                    dispute?.seanceCost * (10 / 100)
                                )
                              }
                            >
                              10%
                            </Button>
                            <Button
                              type="primary"
                              onClick={() =>
                                form.setFieldValue(
                                  "refundAmount",
                                  dispute?.seanceCost && dispute?.seanceCost / 2
                                )
                              }
                            >
                              50%
                            </Button>
                            <Button
                              type="primary"
                              onClick={() =>
                                form.setFieldValue(
                                  "refundAmount",
                                  dispute?.seanceCost
                                )
                              }
                            >
                              100%
                            </Button>
                          </Space>
                        </Space>
                      ) : null}
                    </Form.Item>
                  )}

                {dispute?.rating &&
                  dispute?.rating <= 3 &&
                  disputeStatus === EDisputeStatus.CLIENTFAVOR && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          marginBottom: 25,
                        }}
                      >
                        Запретить отзыв клиента о сотруднике
                      </p>
                      <Form.Item name="saveReview" valuePropName="checked">
                        <Switch disabled={isOpenDispute} />
                      </Form.Item>
                    </div>
                  )}

                {isOpenDispute && (
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Закрыть диспут
                    </Button>
                  </Form.Item>
                )}
              </Form>

              {dispute?.rating && dispute?.rating <= 3 ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: 16,
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Отзыв и оценка клиента</p>
                    <div style={{ display: "flex", gap: 4, height: 27 }}>
                      <StarFilled />
                      <p>{dispute?.rating}</p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 300,
                      lineHeight: "16px",
                    }}
                  >
                    {dispute?.text}
                  </p>
                </>
              ) : null}
            </Card>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </Show>
  );
};
