import { FC } from "react";
import { Form, Input } from "antd";

import { Filter } from "./Filter";

import { IFilter } from "../../common/types";

export const DisputeFilter: FC<IFilter> = ({
  formProps,
  filters,
  setClearFilters,
}) => {
  return (
    <Filter
      formProps={formProps}
      setClearFilters={setClearFilters}
      filters={filters}
    >
      <Form.Item label="Никнейм" name="nickname">
        <Input placeholder="Никнейм" />
      </Form.Item>
    </Filter>
  );
};
