import { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { settings } from "../common/settings";
import { DataProvider } from "@refinedev/core";
import { getQuery } from "../common/functions";
import { IClient, ICommission, IResponse } from "../common/types";

export const CustomServer = (
  apiUrl: string,
  httpClient: AxiosInstance
): Omit<
  DataProvider,
  | "update"
  | "createMany"
  | "updateMany"
  | "deleteOne"
  | "deleteMany"
  | "getApiUrl"
  | "custom"
> => ({
  // @ts-ignore
  getList: async ({
    resource,
    hasPagination = true,
    pagination = { current: 1, pageSize: 20 },
    filters,
    sort,
  }) => {
    const url = `${apiUrl}/${resource}`;
    const { data, headers } = await httpClient.get(`${url}`);

    return {
      data: data,
      total: data.length,
    };
  },

  getMany: async ({ resource, ids }) => {
    let allData: any = [];

    for (const id of ids) {
      const { data } = await httpClient.get(`${apiUrl}/${resource}/${id}`);
      allData = [...allData, data];
    }

    return {
      data: allData,
    };
  },
  // @ts-ignore
  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    if (!id) {
      return {
        data: null,
      };
    }

    const { data } = await httpClient.get(url);

    return {
      data: data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables, {
      headers: {
        Authorization: `Bearer ${Cookies.get(settings.auth.accessToken)}`,
        "Content-Type": "application/json",
      },
    });

    return {
      data,
    };
  },
});

export const UserProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): Omit<
  DataProvider,
  | "update"
  | "createMany"
  | "updateMany"
  | "deleteOne"
  | "deleteMany"
  | "getApiUrl"
  | "getMany"
  | "custom"
> => ({
  // @ts-ignore
  getList: async ({
    resource,
    pagination = { current: 1, pageSize: 20 },
    filters,
    metaData,
    sort,
  }) => {
    let query = getQuery({ pagination, filters, sort });

    let url = `${settings.api.url}/users/${resource}${query}`;
    switch (resource) {
      case "subscriptions": {
        url = `${settings.api.url}/users/performers/subscriptions${query}`;
        break;
      }
      case "blacklist": {
        url = `${settings.api.url}/blacklist_phones${query}`;
        break;
      }
      case "salonPerformers": {
        if (metaData && "id" in metaData) {
          url = `${settings.api.url}/users/salons/${metaData.id}/performers${query}`;
        }
        break;
      }
    }
    const { data }: IResponse<IClient> = await httpClient.get(url);
    return {
      data: data.data,
      total: data.meta.totalItems,
    };
  },
  // @ts-ignore
  getOne: async ({ resource, id }) => {
    let { data } = await httpClient.get(
      `${settings.api.url}/users/${resource}/${id}`
    );
    let entities = {};

    if (resource === "performers") {
      const { data: images } = await httpClient.get(
        `${settings.api.url}/users/${resource}/${id}/images`
      );
      const { data: videos } = await httpClient.get(
        `${settings.api.url}/users/${resource}/${id}/videos`
      );
      const { data: tariffs } = await httpClient.get(
        `${settings.api.url}/users/${resource}/${id}/tariffs`
      );
      const { data: services } = await httpClient.get(
        `${settings.api.url}/users/${resource}/${id}/services`
      );
      entities = {
        images: images.data,
        videos: videos.data,
        tariffs,
        services,
      };
    }

    return {
      data: {
        data,
        ...entities,
      },
    };
  },
});
export const SupportMessageProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): {
  getList: ({
    resource,
    pagination,
    filters,
    metaData,
    sort,
  }: {
    resource: any;
    pagination?: any;
    filters: any;
    metaData: any;
    sort: any;
  }) => Promise<{ total: any; data: any }>;
} => ({
  // @ts-ignore
  getList: async ({
    resource,
    pagination = { current: 1, pageSize: 20 },
    filters,
    metaData,
    sort,
  }) => {
    let query = getQuery({ pagination, filters, sort });

    let url = `${settings.api.url}/users/user-support${query}`;
    const { data } = await httpClient.get(url);
    return {
      data: data.data,
      total: data.meta.totalItems,
    };
  },
});

export const SupportChatProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): Omit<
  DataProvider,
  | "update"
  | "createMany"
  | "updateMany"
  | "deleteOne"
  | "deleteMany"
  | "getApiUrl"
  | "getMany"
  | "custom"
> => ({
  // @ts-ignore
  getList: async ({
    resource,
    pagination = { current: 1, pageSize: 20 },
    filters,
    metaData,
    sort,
  }) => {
    let query = getQuery({ pagination, filters, sort });

    let url = `${settings.api.url}/users/user-support`;
    const { data }: IResponse<IClient> = await httpClient.get(url);
    return {
      data: data.data,
      total: data.meta.totalItems,
    };
  },
});
export const FinanceProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): Omit<
  DataProvider,
  | "getOne"
  | "update"
  | "createMany"
  | "updateMany"
  | "deleteOne"
  | "deleteMany"
  | "getApiUrl"
  | "getMany"
  | "custom"
> => ({
  // @ts-ignore
  getList: async ({
    resource,
    hasPagination = true,
    pagination = { current: 1, pageSize: 20 },
    filters,
    sort,
  }) => {
    let entities = {};
    let query = getQuery({ pagination, filters, sort });

    if (resource === "filters") {
      const { data: performers } = await httpClient.get(
        `${settings.api.url}/users/performers?select=id,name&limit=999`
      );
      const { data: salons } = await httpClient.get(
        `${settings.api.url}/users/salons?select=id,name&limit=999`
      );

      entities = {
        performers: performers.data,
        salons: salons.data,
      };
    }

    if (resource === "transactions") {
      const { data: transactions } = await httpClient.get(
        `${settings.api.url}/operations${query}`
      );
      return {
        data: transactions.data,
        total: transactions.meta.totalItems,
      };
    }

    if (resource === "balance") {
      const { data: balance } = await httpClient.get(
        `${settings.api.url}/payment-history${query}`
      );
      return {
        data: balance.data,
        total: balance.meta.totalItems,
      };
    }

    if (resource === "profits") {
      const { data: profits } = await httpClient.get(
        `${settings.api.url}/profits${query}`
      );
      return {
        data: profits.data,
        total: profits.meta.totalItems,
      };
    }

    if (resource === "cryptocurrency") {
      const { data: cryptocurrency } = await httpClient.get(
        `${settings.api.url}/crypto-transactions${query}`
      );
      return {
        data: cryptocurrency.data,
        total: cryptocurrency.meta.totalItems,
      };
    }

    if (resource === "statistics") {
      let statQuery = !!filters?.length
        ? `?${filters
            .map((f) => `${"field" in f && f.field}=${f.value}`)
            .join("&")}`
        : "";
      const { data: statistics } = await httpClient.get(
        `${settings.api.url}/operations/statistics${statQuery}`
      );
      return {
        data: statistics,
      };
    }

    return {
      ...entities,
    };
  },
});

export const OrderProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): Omit<
  DataProvider,
  | "getOne"
  | "update"
  | "createMany"
  | "updateMany"
  | "deleteOne"
  | "deleteMany"
  | "getApiUrl"
  | "getMany"
> => ({
  // @ts-ignore
  getList: async ({
    resource,
    hasPagination = true,
    pagination = { current: 1, pageSize: 20 },
    filters,
    sort,
  }) => {
    let url = "users/orders";
    if (resource === "disputes") {
      url += "/disputes";
    }
    let query = getQuery({ pagination, filters, sort });
    const { data } = await httpClient.get(`${settings.api.url}/${url}${query}`);
    return {
      data: data.data,
      total: data.meta.totalItems,
    };
  },
  // @ts-ignore
  getOne: async ({ resource, id }) => {
    let url = "users/orders";
    if (resource === "disputes") {
      url += "/disputes";
    }
    const { data } = await httpClient.get(`${settings.api.url}/${url}/${id}`);
    return {
      data: data,
    };
  },
});

export const CommissionProvider = (
  apiUrl: string,
  httpClient: AxiosInstance
): Omit<
  DataProvider,
  | "getOne"
  | "update"
  | "createMany"
  | "updateMany"
  | "deleteOne"
  | "deleteMany"
  | "getApiUrl"
  | "getMany"
> => ({
  // @ts-ignore
  getList: async ({ resource }) => {
    let url = `${settings.api.url}/commission`;
    const { data }: IResponse<ICommission> = await httpClient.get(url);
    return {
      data: data,
    };
  },
  // @ts-ignore
  getOne: async ({ resource, id }) => {
    let url = "commission";
    const { data } = await httpClient.get(`${settings.api.url}/${url}/${id}`);
    return {
      data: data,
    };
  },
});
