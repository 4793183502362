import {
  useGetLocale,
  useSetLocale,
  useGetIdentity, useLogout, useTranslate,
} from "@pankod/refine-core";
import {
  Layout as AntdLayout,
  Avatar,
  Dropdown,
  MenuProps,
  Space,
  Typography,
  theme,
} from "antd";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {ColorModeContext} from "contexts/color-mode";
import {Icons, Menu, Switch} from "@pankod/refine-antd";
import {UserOutlined} from '@ant-design/icons';

const {Text} = Typography
const {useToken} = theme

type IUser = {
  id: number
  name: string
  avatar: string
}

export const Header: React.FC = () => {
  const {token} = useToken();
  const {data: user} = useGetIdentity<IUser>();
  const {mode, setMode} = useContext(ColorModeContext);
  const {mutate: logout} = useLogout();

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
      }}
    >
      <Space>
        <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
        />
        <Dropdown trigger={["click"]} overlay={() => {
          return (
            <Menu>
              <Menu.Item
                key="logout"
                onClick={() => logout()}
                icon={<Icons.LogoutOutlined/>}
              >
                Выйти
              </Menu.Item>
            </Menu>
          )
        }}>
          <div className="avatar">
            <UserOutlined />
          </div>
        </Dropdown>
      </Space>
    </AntdLayout.Header>
  );
};
