import { IParams } from "./types";
import { CrudFilters, CrudSorting, Pagination } from "@refinedev/core";
import { FormProps } from "@pankod/refine-antd";

export const setRequestParams = (data: IParams | null | undefined): string => {
  if (!data || !Object.keys(data).length) {
    return "";
  }
  return "?" + new URLSearchParams(data).toString();
};

export const generateFilters = (filters: CrudFilters) => {
  let filterAsQuery = "";
  let search: string[] = [];
  let filter: string[] = [];
  filters.forEach((f) => {
    if ("field" in f) {
      if (f.field === "search") {
        search.push("search=" + f.value);
      } else {
        filter.push(`filter.${f.field}=$${f.operator}:${f.value}`);
      }
    }
  });
  if (search.length > 0) {
    filterAsQuery = search.join("&");
  }
  if (filter.length) {
    filterAsQuery = [filterAsQuery, ...filter].join("&");
  }
  return new URLSearchParams(filterAsQuery).toString();
};

export const generateSorters = (sorters: CrudSorting) => {
  let sorterAsQuery = "";
  let sorter: string[] = [];
  sorters.forEach((s) => {
    sorter.push(`sortBy=${s.field}:${s.order.toUpperCase()}`);
  });
  if (sorter.length) {
    sorterAsQuery = [sorterAsQuery, ...sorter].join("&");
  }
  return new URLSearchParams(sorterAsQuery).toString();
};

export const generatePagination = (pagination: Pagination) => {
  return new URLSearchParams(
    [`limit=10`, `page=${pagination.current}`].join("&")
  ).toString();
};

export const getQuery = ({
  pagination,
  filters,
  sort,
}: {
  pagination?: Pagination;
  filters?: CrudFilters;
  sort?: CrudSorting;
}) => {
  let query = "";
  if (filters && filters?.length) {
    query = generateFilters(filters);
  }

  if (sort && sort?.length) {
    const sorter = generateSorters(sort);
    query = query ? [query, sorter].join("&") : sorter;
  }

  if (pagination) {
    const page = generatePagination(pagination);
    query = query ? [query, page].join("&") : page;
  }

  return `?${query}`;
};

export const setDocumentTitle = (title?: string) => {
  if (title) {
    document.title = title;
  }
};

export const getTimeWithUnits = (time: number, short: boolean = false) => {
  return Math.floor(time / 60) === 0
    ? `${time} ${short ? "м" : "мин"}`
    : `${(time / 60).toFixed(1)} ч`;
};

export const setInitialFilters = (
  formProps: FormProps,
  filters?: CrudFilters
) => {
  if (!!filters?.length) {
    filters.forEach((f) => {
      let field = "";
      if ("field" in f) {
        field =
          f.field.slice(f.field.length - 2, f.field.length) === "id"
            ? `${f.field.slice(0, f.field.length - 3)}Id`
            : f.field;
      }
      if (field !== "timeslotFrom") {
        formProps.form?.setFieldValue(field, f.value);
      }
    });
  }
};

export const addressShort = (
  address: string,
  letterWidth: number,
  lettersEnd: number
) => {
  const length = address.length / letterWidth;

  if (length < address.length) {
    return (
      address.slice(0, length - lettersEnd - 1) +
      "..." +
      address.slice(-lettersEnd)
    );
  }
};
