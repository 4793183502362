import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Table,
  useTable,
  Form,
  Modal,
  Input,
  message,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  useCustom,
  useCustomMutation,
} from "@pankod/refine-core";
import { getDefaultSortOrder } from "@refinedev/antd";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";

import { ProfitFilter } from "components/filters/ProfitFilter";

import { DATE_TIME_FORMAT, FORM_REQUIRED_RULE } from "../../common/constants";
import { settings } from "../../common/settings";

import {
  EProfitType,
  IChangeConditionsForm,
  IProfit,
  ISettings,
  PROFIT_TYPE,
} from "../../common/types";

export const ProfitList = () => {
  const url = settings.api.backendUrl.toString();
  const { mutate } = useCustomMutation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [changeConditionsLoading, setChangeConditionsLoading] = useState(false);

  const { data: profitSettings, refetch: refetchSettings } =
    useCustom<ISettings>({
      url: `${url}/admin/profits/settings`,
      method: "get",
    });

  const { tableProps, filters, searchFormProps, sorter, tableQueryResult } =
    useTable<IProfit, HttpError>({
      resource: "profits",
      dataProviderName: "financeProvider",
      syncWithLocation: true,
      hasPagination: true,
      initialPageSize: 10,
      errorNotification: {
        type: "error",
        description: "Ошибка",
        message: "Не удалось получить список прибыли",
      },
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { search, createdAt }: any = params;
        filters.push({
          field: "search",
          operator: "eq",
          value: search,
        });
        filters.push({
          field: "createdAt",
          // @ts-ignore
          operator: "btw",
          value:
            createdAt &&
            createdAt[0].format("YYYY-MM-DD") +
              "," +
              createdAt[1].format("YYYY-MM-DD"),
        });
        return filters;
      },
    });

  const changeConditions = (values: IChangeConditionsForm) =>
    mutate(
      {
        url: `${url}/admin/profits/settings`,
        method: "put",
        values,
      },
      {
        onSuccess: async () => {
          await tableQueryResult.refetch();
          setOpen(false);
          await message.success("Условия изменены");
        },
        onSettled: async () => {
          refetchSettings();
          setChangeConditionsLoading(false);
        },
      }
    );

  const handleСhangeConditions = () => {
    let values: IChangeConditionsForm = form.getFieldsValue();
    setChangeConditionsLoading(true);
    changeConditions({
      costBoost: Number(values.costBoost) || null,
      costShift: Number(values.costShift) || null,
      feeSeance: Number(values.feeSeance) || null,
      percentCommon: Number(values.percentCommon) || null,
      percentElite: Number(values.percentElite) || null,
      password: values.password,
    });
  };

  return (
    <Row
      gutter={[16, 16]}
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <Col lg={18} xs={24}>
        <Card title="Прибыль">
          <ProfitFilter formProps={searchFormProps} filters={filters} />

          <Table
            {...tableProps}
            rowKey="id"
            pagination={{
              ...tableProps.pagination,
              pageSize: 10,
              position: ["bottomLeft"],
              style: { marginBottom: 0 },
              hideOnSinglePage: true,
            }}
          >
            <Table.Column
              dataIndex="nickname"
              key="nickname"
              title="Никнейм"
              render={(_: unknown, record: IProfit) =>
                record?.client?.nickname || record?.advertiser?.nickname ? (
                  <Link
                    to={
                      record.userType === "CLIENT"
                        ? `/clients/show/${record.client.id}`
                        : `/performers/show/${record.advertiser.id}`
                    }
                    target="_blank"
                  >
                    {record.userType === "CLIENT"
                      ? record?.client?.nickname
                      : record?.advertiser?.nickname}
                  </Link>
                ) : (
                  "-"
                )
              }
            />

            <Table.Column
              dataIndex="amount"
              key="amount"
              title="Сумма"
              render={(amount: number) =>
                amount ? `${parseFloat(Number(amount).toFixed(2))} $` : "-"
              }
            />

            <Table.Column
              dataIndex="type"
              key="type"
              title="Тип"
              render={(type: string, record: IProfit) =>
                type
                  ? `${PROFIT_TYPE[type as EProfitType]} ${
                      type === EProfitType.COMMISSIONS ||
                      type === EProfitType.WITHDRAWAL
                        ? `(${record.percentage}%)`
                        : ""
                    } ${
                      type === EProfitType.BOOST || type === EProfitType.SHIFT
                        ? `(${record.percentage} шт.)`
                        : ""
                    }`
                  : "-"
              }
            />

            <Table.Column
              dataIndex="createdAt"
              key="createdAt"
              title="Дата"
              defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
              sorter
              render={(createdAt) =>
                createdAt ? moment(createdAt).format(DATE_TIME_FORMAT) : "-"
              }
            />
          </Table>

          <Space style={{ marginTop: 20 }}>
            <Button
              htmlType="submit"
              type="default"
              onClick={() =>
                (window.location.href =
                  "https://api.uwu.dev.pyrobyte.ru/v1/admin/profits/csv")
              }
            >
              Экспортировать СSV
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                form.resetFields();
                setOpen(true);
              }}
            >
              Изменить условия
            </Button>
          </Space>
        </Card>
      </Col>

      <Modal
        confirmLoading={changeConditionsLoading}
        open={open}
        footer={
          <Space style={{ width: "100%", gap: 20 }}>
            <Button htmlType="submit" type="primary" onClick={form.submit}>
              Изменить условия
            </Button>
            <Button
              htmlType="submit"
              type="default"
              onClick={() => {
                setOpen(false);
                form.resetFields();
              }}
            >
              Отменить
            </Button>
          </Space>
        }
        destroyOnClose={true}
        closable={false}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleСhangeConditions}
          initialValues={profitSettings?.data}
        >
          <Form.Item label="Стоимость буста" name="costBoost">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Стоимость смены" name="costShift">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Сборы с сеансов" name="feeSeance">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Процент с обычных сотрудников" name="percentCommon">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Процент с элиты" name="percentElite">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Пароль от админки"
            rules={FORM_REQUIRED_RULE}
          >
            <Input.Password
              visibilityToggle={true}
              type="password"
              placeholder="Пароль"
              size="large"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};
