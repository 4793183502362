import { FC } from "react";
import { useCustomMutation } from "@pankod/refine-core";
import { IModerateButtonProps, ISalon } from "../../common/types";
import { settings } from "../../common/settings";
import { Button } from "@pankod/refine-antd";
import { Space } from "antd";

const ModerateButtons: FC<IModerateButtonProps> = ({ onSuccess, id }) => {
  const { mutate, isLoading, isSuccess } = useCustomMutation<ISalon>();

  const handleModerate = (id: string, isAccept: boolean) => {
    mutate(
      {
        url: `${settings.api.url}/users/performers/${id}/${
          isAccept ? "accept" : "decline"
        }`,
        method: "patch",
        values: {},
      },
      {
        onSuccess: onSuccess,
      }
    );
  };

  return (
    <Space>
      <Button
        disabled={isLoading || isSuccess}
        type="primary"
        onClick={() => (id ? handleModerate(id, true) : null)}
      >
        Принять
      </Button>
      <Button
        disabled={isLoading || isSuccess}
        onClick={() => (id ? handleModerate(id, false) : null)}
      >
        Отклонить
      </Button>
    </Space>
  );
};

export default ModerateButtons;
