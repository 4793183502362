import React, {FC, useEffect} from 'react';
import {Button, Form} from '@pankod/refine-antd';
import {Space} from 'antd';
import {IFilter} from '../../common/types';


export const Filter: FC<IFilter> = ({formProps, children, setClearFilters, filters}) => {
  useEffect(() => {
    if (!filters?.length) {
      formProps.form?.resetFields()
    }
  }, [filters])

  return (
    <Form layout="vertical" {...formProps}>
      <Space align="end" className="filter-container">

        {children}

        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
          >
            Отфильтровать
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            className="width-100-pr"
            htmlType="button"
            onClick={() => {
              setClearFilters?.(true)
              formProps?.form?.resetFields()
              formProps?.form?.submit()
            }}
          >
            Очистить
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}
