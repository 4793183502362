import React, {FC} from 'react';
import {Descriptions, Modal, Row} from '@pankod/refine-antd';
import ServiceCards from './ServiceCards';
import { ITariffModalProps} from '../../common/types';


const TariffModal: FC<ITariffModalProps> = ({selectedTariff, setSelectedTariff}) => {
  const nameEn = selectedTariff?.tariff?.name.EN ?? selectedTariff?.nameEn

  return (
    <Modal
      open={selectedTariff !== null}
      footer={null}
      title={`Тариф: ${selectedTariff?.tariff?.name?.RU ?? selectedTariff?.nameRu} ${nameEn ? `(${nameEn})` : ``} `}
      onCancel={() => setSelectedTariff(null)}
    >
      <Descriptions
        column={1}
        bordered={true}
        layout="vertical"
        size="small"
      >
        <Descriptions.Item label="Описание">
          {selectedTariff?.descriptionRu || '-'}
        </Descriptions.Item>

        <Descriptions.Item label="Описание (en)">
          {selectedTariff?.descriptionEn || '-'}
        </Descriptions.Item>

        <Descriptions.Item label="Услуги">
          <Row gutter={[16, 16]}>
            {!!selectedTariff?.services.length && (
              <ServiceCards
                isShort={true}
                services={selectedTariff?.services}
                span={24}
              />
            )}
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}

export default TariffModal
