import {AuthProvider} from "@pankod/refine-core";
import Cookies from "js-cookie";
import {settings} from "../common/settings";
import {axiosInstance} from '../api/instance';
import {COOKIE_OPTIONS} from '../common/constants';
import {notification} from 'antd';

const ADMIN_USER = {
  id: 1,
  name: "Пользователь",
  avatar: "https://i.pravatar.cc/300",
}

export const authProvider: AuthProvider = {
  login: async ({ username, password, remember }) => {
    try {
      const response = await axiosInstance.request({
        method: 'post',
        url: `${settings.api.url}/auth/signin`,
        data: {username, password}
      })

      if (response.data) {
        Cookies.set(settings.auth.accessToken, response.data.accessToken, remember ? COOKIE_OPTIONS : {})
        Cookies.set(settings.auth.refreshToken, response.data.refreshToken, remember ? COOKIE_OPTIONS : {})
        Cookies.set(settings.auth.userType, response.data.userType, remember ? COOKIE_OPTIONS : {})
        if (remember) {
          Cookies.set(settings.auth.remember, remember, COOKIE_OPTIONS)
        }
        return {
          success: true,
          redirectTo: '/'
        }
      }
    } catch (e) {
      notification.error({
        message: 'Неверный логин или пароль'
      })
      return {
        success: false
      }
    }
  },
  logout: () => {
    Cookies.remove(settings.auth.accessToken)
    Cookies.remove(settings.auth.refreshToken)
    Cookies.remove(settings.auth.userType)
    Cookies.remove(settings.auth.remember)
    return Promise.resolve()
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = Cookies.get(settings.auth.accessToken)
    if (token) {
      return Promise.resolve()
    }
    return Promise.reject()
  },
  getPermissions: async () => {
    return Promise.resolve(ADMIN_USER)
  },
  getUserIdentity: async () => {
    const token = Cookies.get(settings.auth.accessToken)
    if (!token) {
      return Promise.reject()
    }
    return Promise.resolve(ADMIN_USER)
  },
}
