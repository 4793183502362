import {
  EAppearanceCategory,
  EDisputeStatus,
  EMeetingPlaceType,
  EMeetingType,
  EModerationStatus,
  EOperationType,
  EOrderStatus,
  EServiceType,
  EStatus,
  EUserType,
  IKeyName,
} from "./types";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT_ISO = "YYYY-MM-DDTHH:mm:ss";

export const DATE_TIME_FORMAT = "DD MMMM YYYY HH:mm";
export const TIME_FORMAT = "HH:mm";

export const FORM_REQUIRED_RULE = [
  {
    required: true,
    message: "Поле обязательно к заполнению",
  },
];

export const COOKIE_OPTIONS = {
  expires: 365,
};

export const SERVICE_TYPE: IKeyName<EServiceType>[] = [
  {
    key: EServiceType.DEFAULT,
    name: "Стандарт",
  },
  {
    key: EServiceType.CUSTOM,
    name: "Настраиваемый",
  },
  {
    key: EServiceType.TOY,
    name: "Игрушка",
  },
  {
    key: EServiceType.APPEARANCE,
    name: "Косплей",
  },
];

export const APPEARANCE_CATEGORY: IKeyName<EAppearanceCategory>[] = [
  {
    key: EAppearanceCategory.COSPLAY,
    name: "Косплей",
  },
  {
    key: EAppearanceCategory.ROLE,
    name: "Роль",
  },
  {
    key: EAppearanceCategory.IMAGE,
    name: "Фотография",
  },
];

export const MODERATE_STATUS: IKeyName<EModerationStatus>[] = [
  {
    key: EModerationStatus.SENT,
    name: "Отправлен на модерацию",
  },
  {
    key: EModerationStatus.NOTMODERATED,
    name: "Не модерирован",
  },
  {
    key: EModerationStatus.ACCEPTED,
    name: "Принят",
  },
  {
    key: EModerationStatus.DECLINED,
    name: "Отклонен",
  },
];

export const MEETING_PLACE_TYPE: IKeyName<EMeetingPlaceType>[] = [
  {
    key: EMeetingPlaceType.NOTSET,
    name: "Не указан",
  },
  {
    key: EMeetingPlaceType.SALON,
    name: "Салон",
  },
  {
    key: EMeetingPlaceType.APARTMENTS,
    name: "Апартаменты",
  },
];

export const ORDER_STATUS: IKeyName<EOrderStatus>[] = [
  {
    key: EOrderStatus.NEW,
    name: "Новая",
  },
  {
    key: EOrderStatus.CANCELED,
    name: "Отменена",
  },
  {
    key: EOrderStatus.COMPLETED,
    name: "Завершена",
  },
  {
    key: EOrderStatus.PAID,
    name: "Оплачена",
  },
  {
    key: EOrderStatus.CONFIRMED,
    name: "Подтверждена",
  },
  {
    key: EOrderStatus.DISPUTED,
    name: "Диспут",
  },
  {
    key: EOrderStatus.AUTOCANCELED,
    name: "Отменен (автоматически)",
  },
];

export const MEETING_TYPE: IKeyName<EMeetingType>[] = [
  {
    key: EMeetingType.AT_ME,
    name: "У клиента",
  },
  {
    key: EMeetingType.VISIT,
    name: "У рекламодателя",
  },
  {
    key: EMeetingType.BOTH,
    name: "У клиента и рекламодателя",
  },
];

export const USER_TYPE: IKeyName<EUserType>[] = [
  {
    key: EUserType.CLIENT,
    name: "Клиент",
  },
  {
    key: EUserType.ADVERTISER,
    name: "Рекламодатель",
  },
  {
    key: EUserType.SALON,
    name: "Салон",
  },
  {
    key: EUserType.INTERNALUSER,
    name: "Внутренний пользователь",
  },
];

export const OPERATION_TYPE: IKeyName<EOperationType>[] = [
  {
    key: EOperationType.REFILL,
    name: "Пополнение",
  },
  {
    key: EOperationType.CONVERSION,
    name: "Обмен",
  },
  {
    key: EOperationType.WITHDRAW,
    name: "Списание",
  },
  {
    key: EOperationType.REFUND,
    name: "Возврат",
  },
  {
    key: EOperationType.CASHOUT,
    name: "Вывод",
  },
];

export const STATUS: IKeyName<EStatus>[] = [
  {
    key: EStatus.PENDING,
    name: "В ожидании",
  },
  {
    key: EStatus.SUCCESS,
    name: "Успешно",
  },
  {
    key: EStatus.ERROR,
    name: "Ошибка",
  },
];

export const DISPUTE_STATUS: IKeyName<EDisputeStatus>[] = [
  {
    key: EDisputeStatus.CLIENTFAVOR,
    name: "Решен в пользу клиента",
  },
  {
    key: EDisputeStatus.ADVERTISERFAVOR,
    name: "Решен в пользу сотрудника",
  },
  {
    key: EDisputeStatus.NEW,
    name: "На рассмотрении",
  },
];
