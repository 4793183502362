import React, {FC} from 'react';
import {IClient, IPerformer, IPerformerTableProps, ISalon} from '../../../common/types';
import {Dropdown, Icons, Menu, ShowButton, Table} from '@pankod/refine-antd';
import {Link} from '@pankod/refine-react-router-v6';
import {getDefaultSortOrder} from '@refinedev/antd';
import moment from 'moment/moment';
import {DATE_TIME_FORMAT} from '../../../common/constants';
import {FinanceButton} from '../../../components/buttons/FinanceButton';


export const PerformersTable: FC<IPerformerTableProps> = ({tableProps, sorter, withActions = true}) => {
  return (
    <Table rowKey="id"{...tableProps}>
      <Table.Column
        dataIndex="name"
        key="name"
        title="Наименование"
        render={(name, record: IClient) => <Link to={`/performers/show/${record.id}`}>{name ?? record.nickname}</Link>}
      />

      <Table.Column
        dataIndex="address"
        key="address"
        title="Местоположение"
        render={(address) => address || '-'}
      />

      <Table.Column
        dataIndex="createdAt"
        key="createdAt"
        title="Дата регистрации"
        defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
        sorter
        render={(createdAt) => moment(createdAt).format(DATE_TIME_FORMAT)}
      />

      <Table.Column
        dataIndex="isBlocked"
        key="isBlocked"
        title="Статус"
        render={(isBlocked) => isBlocked ? 'Заблокирован' : 'Активен'}
      />

      <Table.Column
        dataIndex="rating"
        key="rating"
        title="Рейтинг"
        defaultSortOrder={getDefaultSortOrder('rating', sorter)}
        sorter
      />

      <Table.Column
        dataIndex="isModerated"
        key="isModerated"
        title="Верифицирован"
        render={(isBlocked) => isBlocked ? 'Да' : 'Нет'}
      />
        <Table.Column
            dataIndex="moderationStatusElite"
            key="moderationStatusElite"
            title="Элита"
            render={(moderationStatusElite) => {
                if (moderationStatusElite === 'NOTMODERATED' || moderationStatusElite === 'DECLINED') {
                    return 'Нет'
                }
                if (moderationStatusElite === 'ACCEPTED'){
                    return 'Да'
                }
                if (moderationStatusElite === 'SENT'){
                    return 'На рассмотрении'
                }
            }}
        />

      <Table.Column
        dataIndex="subscription"
        key="subscription"
        title="Подписка"
        render={(subscription) => subscription ? 'Есть' : 'Нет'}
      />



      {withActions && (
        <Table.Column
          fixed="right"
          title="Действия"
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_, record: ISalon) => (
            <Dropdown
              trigger={["click"]}
              overlay={(
                <Menu mode="vertical">
                  <Menu.Item key="show">
                    <ShowButton
                      size="small"
                      recordItemId={record.id}
                      className="action-table-button"
                    >
                      Просмотр
                    </ShowButton>
                  </Menu.Item>

                  <Menu.Item key="finance">
                    <FinanceButton id={record.id} section="advertiser"/>
                  </Menu.Item>
                </Menu>
              )}
            >
              <Icons.MoreOutlined className={"table-dropdown-icon"}/>
            </Dropdown>
          )}
        />
      )}
    </Table>
  )
}