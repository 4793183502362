import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Dropdown,
  Form,
  Icons,
  Menu,
  Row,
  ShowButton,
  Table,
  useTable,
} from "@pankod/refine-antd";
import {
  IClient,
  ICurrencyRate,
  IData,
  IOrder,
  IOrderFilter,
  IPerformer,
  ISalon,
} from "../../common/types";
import { CrudFilters, HttpError, useCustom } from "@pankod/refine-core";
import { OrderFilter } from "../../components/filters/OrderFilter";
import moment, { Moment } from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../common/constants";
import { Link } from "@pankod/refine-react-router-v6";
import { settings } from "../../common/settings";
import { getDefaultSortOrder } from "@refinedev/antd";

export const OrderList = () => {
  const url = settings.api.backendUrl.toString();

  const { data: currency } = useCustom<ICurrencyRate>({
    url: `${url.slice(0, url.length - 3)}/currency_rates`,
    method: "get",
  });

  const { tableProps, filters, searchFormProps, sorter } = useTable<
    IOrder,
    HttpError,
    IOrderFilter
  >({
    resource: "orders",
    dataProviderName: "orderProvider",
    syncWithLocation: true,
    errorNotification: {
      type: "error",
      description: "Ошибка",
      message: "Не удалось получить список заказов",
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { clientId, advertiserId, salonId, timeslotFrom } = params;
      filters.push({
        field: "client.id",
        operator: "eq",
        value: clientId,
      });
      filters.push({
        field: "advertiser.id",
        operator: "eq",
        value: advertiserId,
      });
      filters.push({
        field: "timeslotFrom",
        operator: "gte",
        value:
          timeslotFrom && timeslotFrom[0]?.startOf("day").format(DATE_FORMAT),
      });
      filters.push({
        field: "timeslotFrom",
        operator: "lte",
        value:
          timeslotFrom && timeslotFrom[1]?.endOf("day").format(DATE_FORMAT),
      });
      filters.push({
        field: "salon.id",
        operator: "eq",
        value: salonId,
      });
      return filters;
    },
  });

  const { data: clients } = useCustom<IData<IClient[]>>({
    url: `${settings.api.url}/users/clients?select=id,name&limit=999`,
    method: "get",
  });

  const { data: performers } = useCustom<IData<IPerformer[]>>({
    url: `${settings.api.url}/users/performers?select=id,name&limit=999`,
    method: "get",
  });

  const { data: salons } = useCustom<IData<ISalon[]>>({
    url: `${settings.api.url}/users/salons?select=id,name&limit=999`,
    method: "get",
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <Card title="Заказы">
          <OrderFilter
            formProps={searchFormProps}
            clients={clients?.data?.data}
            performers={performers?.data?.data}
            salons={salons?.data?.data}
            filters={filters}
          />

          <Table rowKey="id" {...tableProps}>
            <Table.Column
              dataIndex="id"
              key="id"
              title="Номер"
              render={(name, record: IClient) => (
                <Link to={`/orders/show/${record?.id}`}>{name}</Link>
              )}
            />

            <Table.Column
              dataIndex="timeslotFrom"
              key="timeslotFrom"
              title="Дата заказа"
              defaultSortOrder={getDefaultSortOrder("timeslotFrom", sorter)}
              sorter
              render={(createdAt) =>
                createdAt ? moment(createdAt).format(DATE_TIME_FORMAT) : "-"
              }
            />

            <Table.Column
              dataIndex="client"
              key="client"
              title="Клиент"
              render={(client: IClient) => client?.nickname}
            />

            <Table.Column
              dataIndex="advertiser"
              key="advertiser"
              title="Рекламодатель"
              render={(advertiser: IPerformer) => advertiser?.nickname}
            />

            <Table.Column
              dataIndex="salon"
              key="salon"
              title="Салон"
              render={(salon: ISalon) => (salon?.id ? salon.name : "-")}
            />

            <Table.Column
              dataIndex="coast"
              key="coast"
              title="Сумма заказа"
              render={(_: unknown, order: IOrder) => {
                const dai = currency?.data?.rates?.DAI?.RUB
                  ? (
                      Number(order.costRub) /
                      100 /
                      currency?.data?.rates?.DAI?.RUB
                    ).toFixed(2)
                  : "-";
                return `${Number(order.costRub) / 100} руб (${dai} DAI)`;
              }}
            />

            <Table.Column
              fixed="right"
              title="Действия"
              dataIndex="actions"
              key="actions"
              align="center"
              render={(_, record: ISalon) => (
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu mode="vertical">
                      <Menu.Item key="show">
                        <ShowButton
                          size="small"
                          recordItemId={record.id}
                          className="action-table-button"
                        >
                          Просмотр
                        </ShowButton>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Icons.MoreOutlined className={"table-dropdown-icon"} />
                </Dropdown>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Row>
  );
};
