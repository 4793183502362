import { Button, Col, Descriptions, Row, Show } from "@pankod/refine-antd";
import { Link } from "@pankod/refine-react-router-v6";
import { useEffect, useState } from "react";
import { useOne, useResource } from "@pankod/refine-core";

import BanUserButton from "../../components/buttons/BanUserButton";
import { LoaderScreen } from "../../components/layout/LoaderScreen";
import ClientInfo from "./components/ClientInfo";

import { IClient, IClientData } from "../../common/types";

export const ClientShow = () => {
  const { id } = useResource();
  const [address, setAddress] = useState<string | null>(null);
  const [client, setClient] = useState<IClient>();
  const {
    data: response,
    isLoading,
    refetch,
  } = useOne<IClientData>({
    resource: "clients",
    dataProviderName: "userProvider",
    id: id || "",
    errorNotification: {
      type: "error",
      description: "Ошибка",
      message: "Не удалось получить информацию о клиенте",
    },
  });

  useEffect(() => {
    if (response) {
      const clientData = response.data.data;
      setClient(clientData);
      let addressParts: string[] = [];
      if (clientData?.country?.name?.RU) {
        addressParts.push(clientData?.country?.name?.RU);
      }
      if (clientData?.region?.name?.RU) {
        addressParts.push(clientData?.region?.name?.RU);
      }
      if (clientData?.city?.name?.RU) {
        addressParts.push(clientData?.city?.name?.RU);
      }
      if (clientData?.district?.name?.RU) {
        addressParts.push(clientData?.district?.name?.RU);
      }
      if (clientData?.underground?.name?.RU) {
        addressParts.push(clientData?.underground?.name?.RU);
      }
      setAddress(addressParts.join(", "));
    }
  }, [response]);

  if (isLoading) {
    return <LoaderScreen />;
  }

  return (
    <Show
      canDelete={false}
      breadcrumb={false}
      isLoading={isLoading}
      headerProps={{
        title: `Просмотр клиента ${client?.nickname}`,
      }}
      headerButtons={
        <BanUserButton
          section="clients"
          id={client?.id}
          isBlocked={client?.isBlocked || false}
          isGhost={false}
          onSuccess={refetch}
        />
      }
    >
      <Row gutter={[24, 12]}>
        <Col xs={24} md={24}>
          <ClientInfo client={client}>
            <Descriptions.Item label="Переход к арбитражам">
              <Link
                to={`/disputes?filters[0][field]=client.id&filters[0][operator]=eq&filters[0][value]=${client?.id}`}
              >
                <Button size="small" type="primary">
                  Перейти
                </Button>
              </Link>
            </Descriptions.Item>

            <Descriptions.Item label="По реферальной ссылке">
              {client?.referral ?? "-"}
            </Descriptions.Item>
          </ClientInfo>

          <Descriptions
            style={{ marginTop: 20 }}
            column={1}
            bordered={true}
            title="Параметры"
            size="small"
            layout="vertical"
          >
            <Descriptions.Item label="Пол">
              {client?.gender?.name?.RU || "-"}
            </Descriptions.Item>

            <Descriptions.Item label="Телосложение">
              {client?.physique?.name?.RU || "-"}
            </Descriptions.Item>

            <Descriptions.Item label="Вес">
              {client?.weightRange
                ? `от ${client?.weightRange?.min} до ${client?.weightRange?.max} кг`
                : "-"}
            </Descriptions.Item>

            <Descriptions.Item label="Возраст">
              {client?.ageRange
                ? `от ${client?.ageRange?.min} до ${client?.ageRange?.max} лет`
                : "-"}
            </Descriptions.Item>

            <Descriptions.Item label="Национальность">
              {client?.ethnos?.name?.RU || "-"}
            </Descriptions.Item>

            <Descriptions.Item label="Адрес">
              {address || "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Show>
  );
};
