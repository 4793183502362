import React, {FC, useEffect, useState} from 'react';
import {
  useTitle,
  CanAccess,
  ITreeMenu,
  useMenu,
  useRouterContext, usePermissions,
} from '@pankod/refine-core';
import {AntdLayout, Menu, Grid, Icons} from '@pankod/refine-antd';
import {setDocumentTitle} from '../../../common/functions';
const {UnorderedListOutlined} = Icons


export const Sider: FC = () => {
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [collapsed, setCollapsed] = useState<boolean>(!!localStorage.getItem('collapsed'))
  const {data: permissions} = usePermissions<string>()
  const {Link} = useRouterContext()
  const Title = useTitle()
  const {menuItems, selectedKey, defaultOpenKeys} = useMenu()
  const breakpoint = Grid.useBreakpoint()
  const {SubMenu} = Menu

  useEffect(() => {
    const title = menuItems.find(mi => mi.key === selectedKey)
    setDocumentTitle(title?.label)
  }, [selectedKey])
  
  useEffect(() => {
    if (collapsed) {
      localStorage.setItem('collapsed', 'yes')
    } else {
      if (!collapsed && isFirstRender) {
        setIsFirstRender(false)
      }
      localStorage.removeItem('collapsed')
    }
  }, [collapsed])

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string, permissions: any) => {
    return tree.map((item: ITreeMenu) => {
      const {
        icon,
        label,
        route,
        name,
        children,
        parentName,
        options
      } = item

      if (children.length > 0) {
        return (
          <SubMenu
            key={route}
            icon={icon ?? <UnorderedListOutlined/>}
            title={options?.label || label}
          >
            {renderTreeView(children, selectedKey, permissions)}
          </SubMenu>
        )
      }

      const isSelected = route === selectedKey
      const isRoute = !(parentName !== undefined && children.length === 0)

      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list" params={{userRoles: permissions}}>
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? 'bold' : 'normal',
              color: !isSelected ? 'white' : 'inherit'
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined/>)}
          >
            <Link to={route}>{options?.label || label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" style={{color: !isSelected ? 'white' : 'inherit'}}/>
            )}
          </Menu.Item>
        </CanAccess>
      )
    })
  }

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(c: boolean) => {
        if (!isFirstRender) {
          setCollapsed(c)
        } else {
          setIsFirstRender(false)
        }
      }}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
      >
        {renderTreeView(menuItems, selectedKey, permissions)}
      </Menu>
    </AntdLayout.Sider>
  )
}
