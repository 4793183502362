import React, {useEffect, useState} from 'react';
import {Card, Collapse, Descriptions, Show, Tag} from '@pankod/refine-antd';
import OrderDetail from './components/OrderDetail';
import {useResource} from '@pankod/refine-core';


export const CryptocurrencyShow = () => {
  const {id} = useResource()
  
  return (
    <Show
      canDelete={false}
      breadcrumb={false}
      headerProps={{
        title: `Просмотр заказа`
      }}
      headerButtons={<span/>}
    >
      <OrderDetail id={id?.toString() || null}/>
    </Show>
  )
}
