import React, {FC, ReactNode} from 'react';
import {IClient} from '../../../common/types';
import {Descriptions} from '@pankod/refine-antd';
import moment from 'moment';
import {DATE_TIME_FORMAT} from '../../../common/constants';

const ClientInfo: FC<{ client?: IClient | null, children?: ReactNode }> = ({client, children}) => {
  return (
    <Descriptions
      column={1}
      bordered={true}
      title="Клиент"
      layout="vertical"
      size="small"
    >
      <Descriptions.Item label="ФИО">
        {client?.name || '-'}
      </Descriptions.Item>
      
      <Descriptions.Item label="Никнейм">
        {client?.nickname || '-'}
      </Descriptions.Item>
      
      <Descriptions.Item label="Телефон">
        {client?.phone || '-'}
      </Descriptions.Item>

      <Descriptions.Item label="Баланс">
        {client?.balance ?? 0}
      </Descriptions.Item>

      <Descriptions.Item label="Статус">
        {client?.isBlocked ? 'Заблокирован' : 'Активный'}
      </Descriptions.Item>
  
      <Descriptions.Item label="Рейтинг">
        {client?.rating?.value || '-'}
      </Descriptions.Item>
      
      <Descriptions.Item label="Дата создания">
        {client?.createdAt ? moment(client?.createdAt).format(DATE_TIME_FORMAT) : '-'}
      </Descriptions.Item>

      {children}
    </Descriptions>
  )
}

export default ClientInfo
