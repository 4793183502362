import React, {FC, useEffect, useState} from 'react';
import {IOrderFilter} from '../../common/types';
import {Filter} from './Filter';
import {DatePicker, Form, Select} from 'antd';
import {setInitialFilters} from '../../common/functions';
import dayjs from 'dayjs';

export const OrderFilter: FC<IOrderFilter> = ({formProps, filters, setClearFilters, salons, performers, clients}) => {
  useEffect(() => {
    setInitialFilters(formProps, filters)
    const timeslots = filters?.filter(t => 'field' in t && t.field === 'timeslotFrom')?.map(t => dayjs(t.value))
    if (timeslots) {
      formProps.form?.setFieldValue('timeslotFrom', timeslots)
    }
  }, [])
  
  return (
    <Filter formProps={formProps} setClearFilters={setClearFilters} filters={filters}>
      <Form.Item
        label="Дата"
        name="timeslotFrom"
      >
        <DatePicker.RangePicker
          placeholder={['Начало', 'Конец']}
          allowClear={true}
          onChange={() => formProps.form?.submit()}
        />
      </Form.Item>
      
      {clients && (
        <Form.Item
          label="По клиенту"
          name="clientId"
        >
          <Select
            placeholder="Не выбран"
            allowClear={true}
            onClear={() => formProps.form?.submit()}
            disabled={!clients.length}
          >
            {clients?.map(client => (
              <Select.Option
                key={client.id}
                value={client.id}
              >
                {client.nickname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      
      {performers && (
        <Form.Item
          label="По рекламодателю"
          name="advertiserId"
        >
          <Select
            placeholder="Не выбран"
            allowClear={true}
            onClear={() => formProps.form?.submit()}
            disabled={!performers.length}
          >
            {performers?.map(performer => (
              <Select.Option
                key={performer.id}
                value={performer.id}
              >
                {performer.nickname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      
      {salons && (
        <Form.Item
          label="По салону"
          name="salonId"
        >
          <Select
            placeholder="Не выбран"
            allowClear={true}
            onClear={() => formProps.form?.submit()}
            disabled={!salons.length}
          >
            {salons?.map(salon => (
              <Select.Option
                key={salon.id}
                value={salon.id}
              >
                {salon.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Filter>
  )
}
