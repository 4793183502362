import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Table,
  useTable,
  Form,
  Modal,
  Input,
  message,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  useCustomMutation,
  useCustom,
} from "@pankod/refine-core";
import { getDefaultSortOrder } from "@refinedev/antd";
import moment from "moment";
import { useState } from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";

import { CryptocurrencyFilter } from "components/filters/CryptocurrencyFilter";

import { DATE_TIME_FORMAT, FORM_REQUIRED_RULE } from "../../common/constants";
import { addressShort } from "common/functions";
import { settings } from "../../common/settings";

import {
  CRYPRO_STATUS,
  CRYPRO_TYPE,
  ECryptoStatus,
  ECryptoType,
  IChangeCryptoWallet,
  ICrypto,
  ICryptoWallet,
} from "../../common/types";

const { Title, Text } = Typography;

export const CryptocurrencyList = () => {
  const url = settings.api.backendUrl.toString();
  const { mutate } = useCustomMutation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [changeWalletLoading, setChangeWalletLoading] = useState(false);

  const { data: walletAddress, refetch: refetchWallet } =
    useCustom<ICryptoWallet>({
      url: `${url}/admin/wallets/commission`,
      method: "get",
    });

  const { data: walletAddressConsolidation } = useCustom<ICryptoWallet>({
    url: `${url}/admin/wallets/consolidation`,
    method: "get",
  });

  const { tableProps, filters, searchFormProps, sorter, tableQueryResult } =
    useTable<ICrypto, HttpError>({
      resource: "cryptocurrency",
      dataProviderName: "financeProvider",
      syncWithLocation: true,
      hasPagination: true,
      initialPageSize: 10,
      errorNotification: {
        type: "error",
        description: "Ошибка",
        message: "Не удалось получить список криптовалют",
      },
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { search, createdAt }: any = params;
        filters.push({
          field: "search",
          operator: "eq",
          value: search,
        });
        filters.push({
          field: "createdAt",
          // @ts-ignore
          operator: "btw",
          value:
            createdAt &&
            createdAt[0].format("YYYY-MM-DD") +
              "," +
              createdAt[1].format("YYYY-MM-DD"),
        });
        return filters;
      },
    });

  const changeWallet = (values: IChangeCryptoWallet) =>
    mutate(
      {
        url: `${url}/admin/wallets/${walletAddress?.data.id}`,
        method: "patch",
        values,
      },
      {
        onSuccess: async () => {
          await tableQueryResult.refetch();
          setOpen(false);
          await message.success("Адрес изменен");
        },
        onSettled: async () => {
          refetchWallet();
          setChangeWalletLoading(false);
        },
      }
    );

  const handleChangeWallet = () => {
    let values: IChangeCryptoWallet = form.getFieldsValue();
    setChangeWalletLoading(true);
    changeWallet({
      wallet: values.wallet || null,
      password: values.password,
    });
  };

  return (
    <Row
      gutter={[16, 16]}
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <Col lg={20} xs={20}>
        <Card title="Криптовалюта">
          <Space style={{ gap: "20px", marginBottom: "20px" }} wrap={true}>
            <Card
              size="small"
              bordered={false}
              style={{
                maxWidth: "363px",
                backgroundColor: "#1C1C1C",
                borderRadius: "16px",
              }}
            >
              <Space
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title
                  style={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontWeight: 600,
                  }}
                >
                  Комиссионный
                </Title>
                <Button
                  style={{
                    paddingInline: "8px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlType="submit"
                  type="primary"
                  onClick={() => {
                    form.resetFields();
                    setOpen(true);
                  }}
                >
                  Изменить
                </Button>
              </Space>
              <Text
                style={{
                  color: "#1668DC",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                }}
              >
                {walletAddress?.data.wallet}
              </Text>
            </Card>
            <Card
              size="small"
              bordered={false}
              style={{
                maxWidth: "363px",
                backgroundColor: "#1C1C1C",
                borderRadius: "16px",
              }}
            >
              <Title
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: 600,
                }}
              >
                Консолидирующий
              </Title>
              <Text
                style={{
                  color: "#1668DC",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                }}
              >
                {walletAddressConsolidation?.data.wallet}
              </Text>
            </Card>
          </Space>
          <CryptocurrencyFilter formProps={searchFormProps} filters={filters} />
          <Table
            {...tableProps}
            rowKey="id"
            pagination={{
              ...tableProps.pagination,
              pageSize: 10,
              position: ["bottomLeft"],
              style: { marginBottom: 0 },
              hideOnSinglePage: true,
            }}
          >
            <Table.Column
              dataIndex="nickname"
              key="nickname"
              title="Никнейм"
              render={(_: unknown, record: ICrypto) =>
                record?.nickname ? (
                  record?.nickname === "system" ? (
                    "Системный"
                  ) : (
                    <Link
                      to={
                        record.userType === "CLIENT"
                          ? `/clients/show/${record.userId}`
                          : `/performers/show/${record.userId}`
                      }
                      target="_blank"
                    >
                      {record?.nickname}
                    </Link>
                  )
                ) : (
                  "-"
                )
              }
            />

            <Table.Column
              dataIndex="from"
              key="from"
              title="С"
              render={(from) =>
                from ? (
                  <Link
                    to={`https://tronscan.org/#/address/${from}/transfers`}
                    target="_blank"
                  >
                    {addressShort(from, 2.8, 6)}
                  </Link>
                ) : (
                  "-"
                )
              }
            />

            <Table.Column
              dataIndex="to"
              key="to"
              title="На"
              render={(to) =>
                to ? (
                  <Link
                    to={`https://tronscan.org/#/address/${to}/transfers`}
                    target="_blank"
                  >
                    {addressShort(to, 2.8, 6)}
                  </Link>
                ) : (
                  "-"
                )
              }
            />

            <Table.Column
              dataIndex="txnHash"
              key="txnHash"
              title="Txn Hash"
              render={(txnHash) =>
                txnHash ? (
                  <Link
                    to={`https://tronscan.org/#/transaction/${txnHash}`}
                    target="_blank"
                  >
                    {addressShort(txnHash, 6, 5)}
                  </Link>
                ) : (
                  "-"
                )
              }
            />

            <Table.Column
              dataIndex="sum"
              key="sum"
              title="Сумма"
              render={(sum) => (sum ? `${sum} $` : "-")}
            />

            <Table.Column
              dataIndex="type"
              key="type"
              title="Тип"
              render={(type) => (type ? CRYPRO_TYPE[type as ECryptoType] : "-")}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Статус"
              render={(status) =>
                status ? CRYPRO_STATUS[status as ECryptoStatus] : "-"
              }
            />

            <Table.Column
              dataIndex="createdAt"
              key="createdAt"
              title="Дата"
              defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
              sorter
              render={(createdAt) =>
                createdAt ? moment(createdAt).format(DATE_TIME_FORMAT) : "-"
              }
            />
          </Table>

          <Button
            htmlType="submit"
            type="default"
            style={{ marginTop: 20 }}
            onClick={() =>
              (window.location.href =
                "https://api.uwu.dev.pyrobyte.ru/v1/admin/crypto-transactions/csv")
            }
          >
            Экспортировать CSV
          </Button>
        </Card>
      </Col>
      <Modal
        confirmLoading={changeWalletLoading}
        open={open}
        footer={
          <Space style={{ width: "100%", gap: 20 }}>
            <Button htmlType="submit" type="primary" onClick={form.submit}>
              Изменить
            </Button>
            <Button
              htmlType="submit"
              type="default"
              onClick={() => {
                setOpen(false);
                form.resetFields();
              }}
            >
              Отменить
            </Button>
          </Space>
        }
        destroyOnClose={true}
        closable={false}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleChangeWallet}
          initialValues={walletAddress?.data}
        >
          <Form.Item
            label="Публичный адрес"
            name="wallet"
            rules={FORM_REQUIRED_RULE}
          >
            <Input placeholder="Адрес" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Пароль от админки"
            rules={FORM_REQUIRED_RULE}
          >
            <Input.Password
              visibilityToggle={true}
              type="password"
              placeholder="Пароль"
              size="large"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};
