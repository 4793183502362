import {
  Card,
  Col,
  Dropdown,
  getDefaultSortOrder,
  Icons,
  Menu,
  Row,
  ShowButton,
  Table,
  useTable,
} from "@pankod/refine-antd";
import moment from "moment/moment";
import { Link } from "@pankod/refine-react-router-v6";
import { HttpError } from "@pankod/refine-core";
import { CrudFilters } from "@refinedev/core";

import { DATE_TIME_FORMAT, DISPUTE_STATUS } from "../../common/constants";
import { IDispute, EDisputeStatus } from "../../common/types";
import { DisputeFilter } from "components/filters/DisputeFilter";

export const DisputeList = () => {
  const { tableProps, filters, searchFormProps, sorter } = useTable<
    IDispute,
    HttpError
  >({
    resource: "disputes",
    dataProviderName: "orderProvider",
    syncWithLocation: true,
    errorNotification: {
      type: "error",
      description: "Ошибка",
      message: "Не удалось получить список диспутов",
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { nickname }: any = params;
      filters.push({
        field: "nickname",
        operator: "eq",
        value: nickname,
      });
      return filters;
    },
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <Card title="Диспуты">
          <DisputeFilter formProps={searchFormProps} filters={filters} />
          <Table
            rowKey="id"
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              pageSize: 10,
              position: ["bottomLeft"],
              style: { marginBottom: 0 },
              hideOnSinglePage: true,
            }}
          >
            <Table.Column
              dataIndex="orderId"
              key="orderId"
              title="№"
              render={(orderId: string) => (
                <Link to={`/orders/show/${orderId}`} target="_blank">
                  {orderId.slice(0, 8)}
                </Link>
              )}
            />

            <Table.Column
              dataIndex="client"
              key="client"
              title="Клиент"
              render={(_: unknown, dispute: IDispute) => (
                <Link
                  to={`/clients/show/${dispute.client?.id}`}
                  target="_blank"
                >
                  {dispute.client?.nickname}
                </Link>
              )}
            />

            <Table.Column
              dataIndex="advertiser"
              key="advertiser"
              title="Сотрудник"
              render={(_: unknown, dispute: IDispute) => (
                <Link
                  to={`/performers/show/${dispute.advertiser?.id}`}
                  target="_blank"
                >
                  {dispute.advertiser?.nickname}
                </Link>
              )}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Статус"
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              sorter
              render={(status: EDisputeStatus) =>
                DISPUTE_STATUS.find((ds) => ds.key === status)?.name || "-"
              }
            />

            <Table.Column
              dataIndex="createdAt"
              key="createdAt"
              defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
              title="Дата сеанса"
              sorter
              render={(createdAt: string) =>
                createdAt ? moment(createdAt).format(DATE_TIME_FORMAT) : "-"
              }
            />

            <Table.Column
              fixed="right"
              title="Действия"
              dataIndex="actions"
              key="actions"
              align="center"
              render={(_, record: IDispute) => (
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu mode="vertical">
                      <Menu.Item key="show">
                        <ShowButton
                          title="Просмотр"
                          size="small"
                          recordItemId={record.id}
                          className="action-table-button"
                        >
                          Просмотр
                        </ShowButton>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Icons.MoreOutlined className={"table-dropdown-icon"} />
                </Dropdown>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Row>
  );
};
