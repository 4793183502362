import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import Loader from './components/layout/Loader';

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <React.Suspense fallback={<Loader />}>
    <App/>
  </React.Suspense>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
