import React, {FC, useEffect, useState} from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  useTable
} from '@pankod/refine-antd';
import {IPerformer, IClient, ILocation} from '../../common/types';
import {GetListResponse} from '@refinedev/core';
import {IResourceComponentsProps} from '@refinedev/core';
import {CrudFilters, HttpError, useCustom} from '@pankod/refine-core';
import {UserFilter} from '../../components/filters/UserFilter';
import {settings} from '../../common/settings';
import {Select} from 'antd';
import {PerformersTable} from './components/PerformersTable';


export const PerformerList: FC<IResourceComponentsProps<GetListResponse<IClient>>> = ({initialData}) => {
  const [clearFilters, setClearFilters] = useState(false)

  const {data: cities} = useCustom<ILocation[]>({
    url: `${settings.api.backendUrl}/locality/cities`,
    method: 'get'
  })

  useEffect(() => {
    if (clearFilters) {
      setClearFilters(false)
    }
  }, [clearFilters])

  const {tableProps, filters, searchFormProps, sorter} = useTable<IPerformer, HttpError>({
    syncWithLocation: true,
    hasPagination: true,
    initialPageSize: 20,
    dataProviderName: 'userProvider',
    errorNotification: {
      type: 'error',
      description: 'Ошибка',
      message: 'Не удалось получить список рекламодателей',
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const {search, isBlocked, cityId, moderationStatusElite}: any = params
      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })
      filters.push({
        field: 'city.id',
        operator: 'eq',
        value: cityId
      })
      filters.push({
        field: 'isBlocked',
        operator: 'eq',
        value: isBlocked,
      })
      filters.push({
        field: 'moderationStatusElite',
        operator: 'eq',
        value: moderationStatusElite
      })
      return filters
    },
  })

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <Card title="Рекламодатели">
          <UserFilter
            formProps={searchFormProps}
            filters={filters}
            setClearFilters={setClearFilters}
          >
            <Form.Item
              label="Город"
              name="cityId"
            >
              <Select
                placeholder="Не выбран"
                allowClear={true}
                onClear={() => searchFormProps.form?.submit()}
                onChange={(e) => searchFormProps.form?.resetFields(['districtId'])}
              >
                {cities?.data?.map(city => (
                  <Select.Option
                    key={city.id}
                    value={city.id}
                  >
                    {city.name.RU}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </UserFilter>

          <PerformersTable
            tableProps={tableProps}
            sorter={sorter}
          />
        </Card>
      </Col>
    </Row>
  )
}
