import React, {FC, useEffect, useState} from 'react';
import {Card, Collapse, Descriptions, Tag} from '@pankod/refine-antd';
import {Link} from '@pankod/refine-react-router-v6';
import {MEETING_TYPE, ORDER_STATUS, TIME_FORMAT} from '../../../common/constants';
import {
  EOrderStatus,
  ICurrencyRate,
  ILocation,
  IMeetingPlace,
  IOrder,
  IService,
  ITariffWithCount
} from '../../../common/types';
import moment from 'moment/moment';
import plural from 'plural-ru';
import {getTimeWithUnits} from '../../../common/functions';
import {useCustom, useOne} from '@pankod/refine-core';
import {settings} from '../../../common/settings';

const OrderDetail: FC<{id: string | null}> = ({ id }) => {
  const url = settings.api.backendUrl.toString()
  const [order, setOrder] = useState<IOrder | null>(null)
  const [meetingPlace, setMeetingPlace] = useState<IMeetingPlace | null>(null)
  const [services, setServices] = useState<IService[]>([])
  const [tariffs, setTariffs] = useState<ITariffWithCount[]>([])
  
  const {data: currency} = useCustom<ICurrencyRate>({
    url: `${url.slice(0, url.length - 3)}/currency_rates`,
    method: 'get'
  })
  
  const {data} = useOne<IOrder>({
    resource: 'orders',
    dataProviderName: 'orderProvider',
    id: id || '',
    errorNotification: {
      type: 'error',
      description: 'Ошибка',
      message: 'Не удалось получить информацию о заказе',
    },
  })

  const dai = currency?.data?.rates?.DAI?.RUB ? ((Number(order?.costRub) / 100) / (currency?.data?.rates?.DAI?.RUB)).toFixed(2) : '-'
  
  useEffect(() => {
    if (data?.data) {
      setOrder(data?.data as IOrder)
      if (!!data?.data?.tariffs?.length) {
        const servicesIds = data.data.tariffs.map(it => it?.tariff?.id || it.id).filter((item, i, ar) => ar.indexOf(item) === i)
        setTariffs(
          servicesIds.map(id => {
            const t = data.data.tariffs!!.find(ot => ot?.tariff?.id === id || ot.id === id)!!
            return {
              ...t,
              count: data.data.tariffs!!.filter(ot => ot?.tariff?.id === id || ot.id === id).length!!
            }
          })
        )
      }
      setServices(data.data?.services?.filter(s => !s.tariffId) || [])
      setMeetingPlace(order?.schedule?.scheduleTemplate?.meetingPlace || null)
    }
  }, [data])
  
  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel
        header="Общее"
        key={1}
      >
        <Descriptions
          column={1}
          bordered={true}
          size="small"
          layout="vertical"
        >
          <Descriptions.Item label="Клиент">
            <Link to={`/clients/show/${order?.client?.id}`} target="_blank">
              {order?.client?.nickname}
            </Link>
          </Descriptions.Item>
        
          <Descriptions.Item label="Рекламодатель">
            <Link to={`/performers/show/${order?.advertiser?.id}`} target="_blank">
              {order?.advertiser?.nickname}
            </Link>
          </Descriptions.Item>
        
          <Descriptions.Item label="Статус">
            {ORDER_STATUS.find(os => os.key === order?.status)?.name}
            {order?.status === EOrderStatus.CANCELED && (
              <Card title="Причина" size="small" style={{marginTop: 10}}>
                {order.cancellationReason}
              </Card>
            )}
          </Descriptions.Item>
        
          <Descriptions.Item label="Дата и время">
            {moment(order?.timeslotFrom).format('DD MMMM')} с {moment(order?.timeslotFrom).format(TIME_FORMAT)} до {moment(order?.timeslotTo).format(TIME_FORMAT)}
          </Descriptions.Item>
        
          <Descriptions.Item label="Локация">
            {MEETING_TYPE?.find(mt => mt.key === meetingPlace?.meetingType)?.name}
            {meetingPlace?.advertiserAddress?.address ? (
              <div>Адрес: {meetingPlace.advertiserAddress.address}, {meetingPlace.advertiserAddress.apartsNumber}, {meetingPlace.advertiserAddress.entrance}</div>
            ) : 'Не указана'}
          </Descriptions.Item>
        
          <Descriptions.Item label="Параметры">
            <Tag>
              {`${plural(order?.tariffsCount || order?.tariffs?.length || 0, '%d тариф', '%d тарифа', '%d тарифов')}`}
            </Tag>
            <Tag>
              {getTimeWithUnits(moment(order?.timeslotTo).diff(moment(order?.timeslotFrom), 'm'))}
            </Tag>
            <Tag>
              {plural(order?.servicesCount || order?.services?.length || 0, '%d услуга', '%d услуги', '%d услуг')}
            </Tag>
            <Tag>
              {plural(order?.cumsCount || order?.tariffs?.reduce((allTime, t) => allTime + t.cumCount, 0) || 0, '%d окончание', '%d окончания', '%d окончаний')}
            </Tag>
          </Descriptions.Item>
        
          <Descriptions.Item label="Стоимость">
            {Number(order?.costRub) / 100} руб ({dai} DAI)
          </Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
    
      <Collapse.Panel
        header="Тарифное время"
        key={2}
      >
        <Descriptions
          column={1}
          bordered={true}
          size="small"
          layout="vertical"
        >
          {tariffs.map(t => {
            const services: IService[] = order?.services?.filter(s => s.tariffId === t.id) || []
            return (
              <Descriptions.Item key={t.id} label={t?.tariff?.name.RU || t.nameRu}>
                <div>Количество: х{t?.count || 1}</div>
                <div>Длительность: {getTimeWithUnits(t?.tariff?.duration || t?.duration)}</div>
                {!!services.length && (
                  <Card
                    title="Услуги внутри тарифа"
                    size="small"
                    style={{marginTop: 10}}
                  >
                    {services?.map(s => {
                      const price = s.priceRub ? Number(s.priceRub) / 100 : null
                      return (
                        <div key={s.id}>
                          <div>- {s?.service?.name?.RU || s?.nameRu}</div>
                          {(price && currency?.data?.rates?.DAI?.RUB) ? <Tag>{price} руб ({price / currency?.data?.rates?.DAI?.RUB} DAI)</Tag> : null}
                        </div>
                      )
                    })}
                  </Card>
                )}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      </Collapse.Panel>
    
      <Collapse.Panel
        header="Дополнительные услуги"
        key={3}
      >
        <Descriptions
          column={1}
          bordered={true}
          size="small"
          layout="vertical"
        >
          {!!services.length ? services?.map(s => {
            const price = s.priceRub ? Number(s.priceRub) / 100 : null
            return (
              <Descriptions.Item
                key={s.id}
                label={s?.service?.name?.RU || s?.nameRu}
              >
                <div>Описание: {s.descriptionRu ?? '-'} {s.descriptionEn ? `(${s.descriptionEn})` : ''}</div>
                <div>По симпатии: {s.bySympathy ? 'да' : 'нет'}</div>
                <Tag>{price} руб {(price && currency?.data?.rates?.DAI?.RUB) ? `(${price / currency.data.rates.DAI.RUB} DAI)` : ''}</Tag>
              </Descriptions.Item>
            )
          }) : (
            <Descriptions.Item label="Отсутствуют">
            </Descriptions.Item>
          )}
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  )
}

export default OrderDetail
