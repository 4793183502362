import React from 'react';
import {Link} from '@pankod/refine-react-router-v6';
import {Button} from '@pankod/refine-antd';
import {LineChartOutlined} from '@ant-design/icons';

export const FinanceButton = ({ id, section }: { id: string, section: 'advertiser' | 'salon'}) => {
  return (
    <Link to={`/finance?filters[0][field]=${section}.id&filters[0][operator]=eq&filters[0][value]=${id}`}>
      <Button
        type="text"
        size="small"
        className="action-table-button"
        icon={<LineChartOutlined />}
      >
        Финансы
      </Button>
    </Link>
  )
}
