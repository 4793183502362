import React, {FC} from 'react';
import {Card, Col, Tag} from '@pankod/refine-antd';
import {APPEARANCE_CATEGORY, SERVICE_TYPE} from '../../common/constants';
import {IServiceCardProps} from '../../common/types';
import {Image} from 'antd';

const ServiceCards: FC<IServiceCardProps> = ({services, span, isShort}) => {
  return <>
    {services.map(service => {
      const defaultService = service?.service
      const r = service.priceRub.toString()
      const u = service.priceUsd.toString()
      const priceRub = service.priceRub > 0 ? r.slice(0, r.length - 2) + `,${r.slice(r.length - 2, r.length)}` : 0
      const priceUsd = service.priceUsd > 0 ? u.slice(0, u.length - 2) + `,${u.slice(u.length - 2, u.length)}` : 0
      const category = defaultService?.category?.name ?? service?.category?.name
      const position = defaultService?.direction?.name ?? service?.direction?.name
      const advertGender = defaultService?.advertiserGender?.name
      const clientGender = defaultService?.clientGender?.name
      const pdTime = service.preparationDuration < 60 ? `${service.preparationDuration} м` : `${service.preparationDuration / 60} ч`
      return (
        <Col span={span || 8} key={service.id}>
          <Card>
            <h3>{defaultService?.name?.RU || service.nameRu} ({defaultService?.name?.EN || service.nameEn})</h3>
            {service?.cover?.urls && (
              <h5>
                Изображение:
                <div>
                  <Image
                    src={service?.cover?.urls['100']}
                    className="performer-image"
                    preview={{
                      src: service.cover.url
                    }}
                  />
                </div>
              </h5>

            )}
            <h5>Описание: {service?.descriptionRu || '-'}</h5>
            <h5>Описание (en): {service?.descriptionEn || ''}</h5>
            <h5>Категория: {category?.RU || '-'} {category?.EN ? `(${category?.EN})` : ''}</h5>
            <h5>Позиция: {position?.RU || '-'} {position?.EN ? `(${position?.EN})` : ''}</h5>
            <h5>Внешний
              вид: {APPEARANCE_CATEGORY.find(ac => ac.key === service.appearanceCategory)?.name || '-'}</h5>
            <h5>По симпатии: {service?.bySympathy ? 'Да' : 'Нет'}</h5>
            <h5>Время ожидания: {pdTime}</h5>
            <h5>Тип: {SERVICE_TYPE.find(st => st.key === service?.type)?.name || '-'}</h5>
            {!isShort && (
              <>
                <h5>Пол рекламодателя: {advertGender?.RU ?? '-'} {advertGender?.EN ? `(${advertGender.EN})` : ''}</h5>
                <h5>Пол клиента: {clientGender?.RU ?? '-'} {clientGender?.EN ? `(${clientGender.EN})` : ''}</h5>
                <div>
                  <h5>Стоимость:</h5>
                  <Tag color="green">{priceRub} P</Tag>
                  <Tag color="green">{priceUsd} USD</Tag>
                </div>
              </>
            )}
          </Card>
        </Col>
      )
    })}
  </>

}

export default ServiceCards
