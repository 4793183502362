import { FC, useEffect } from "react";
import { DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";

import { setInitialFilters } from "../../common/functions";

import { Filter } from "./Filter";

import { IFilter } from "../../common/types";

export const CryptocurrencyFilter: FC<IFilter> = ({
  formProps,
  filters,
  setClearFilters,
}) => {
  useEffect(() => {
    setInitialFilters(formProps, filters);
    const timeslots = filters
      ?.filter((t) => "field" in t && t.field === "timeslotFrom")
      ?.map((t) => dayjs(t.value));
    if (timeslots) {
      formProps.form?.setFieldValue("timeslotFrom", timeslots);
    }
  }, []);

  return (
    <Filter
      formProps={formProps}
      setClearFilters={setClearFilters}
      filters={filters}
    >
      <Form.Item label="Дата" name="timeslotFrom">
        <DatePicker.RangePicker
          placeholder={["Начало", "Конец"]}
          allowClear={true}
          onChange={() => formProps.form?.submit()}
        />
      </Form.Item>

      <Form.Item label="Никнейм/Адрес" name="search">
        <Input placeholder="Никнейм/адрес" />
      </Form.Item>
    </Filter>
  );
};
