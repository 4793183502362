import React, {FC} from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  FormProps,
  Icons,
  Input,
  Menu,
  Row, ShowButton,
  Table,
  useTable
} from '@pankod/refine-antd';
import {IClient, ISalon} from '../../common/types';
import {GetListResponse} from '@refinedev/core';
import {IResourceComponentsProps} from '@refinedev/core';
import {CrudFilters, HttpError} from '@pankod/refine-core';
import moment from 'moment/moment';
import {DATE_TIME_FORMAT} from '../../common/constants';
import BanUserButton from '../../components/buttons/BanUserButton';
import {UserFilter} from '../../components/filters/UserFilter';
import {Link} from '@pankod/refine-react-router-v6';
import {getDefaultSortOrder} from '@refinedev/antd';


export const ClientList: FC<IResourceComponentsProps<GetListResponse<IClient>>> = ({initialData, logQueryResult}) => {
  const {tableProps, tableQueryResult, searchFormProps, filters, sorter} = useTable<IClient, HttpError>({
    queryOptions: {
      initialData
    },
    syncWithLocation: true,
    hasPagination: true,
    initialPageSize: 10,
    dataProviderName: 'userProvider',
    permanentSorter: [],
    errorNotification: {
      type: 'error',
      description: 'Ошибка',
      message: 'Не удалось получить список клиентов',
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const {search, isBlocked}: any = params
      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })
      filters.push({
        field: 'isBlocked',
        operator: 'eq',
        value: isBlocked,
      })
      return filters
    },
  })

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <Card title="Клиенты">
          <UserFilter
            formProps={searchFormProps}
            filters={filters}
          />

          <Table
            {...tableProps}
            rowKey="id"
            pagination={{
              ...tableProps.pagination,
              pageSize: 10
            }}
          >
            <Table.Column
              dataIndex="nickname"
              key="nickname"
              title="Никнейм"
              render={(nickname, record: IClient) => <Link to={`/clients/show/${record.id}`}>{nickname}</Link>}
            />

            <Table.Column
              dataIndex="phone"
              key="phone"
              title="Телефон"
            />

            <Table.Column
              dataIndex="createdAt"
              key="createdAt"
              title="Дата регистрации"
              defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
              sorter
              render={(createdAt) => moment(createdAt).format(DATE_TIME_FORMAT)}
            />

            <Table.Column
              dataIndex="isBlocked"
              key="isBlocked"
              title="Статус"
              render={(isBlocked) => isBlocked ? 'Заблокирован' : 'Активен'}
            />

            <Table.Column
              dataIndex="rating"
              key="rating"
              title="Рейтинг"
            />

            <Table.Column
              fixed="right"
              title="Действия"
              dataIndex="actions"
              key="actions"
              align="center"
              render={(_, record: ISalon) => (
                <Dropdown
                  trigger={["click"]}
                  overlay={(
                    <Menu mode="vertical">
                      <Menu.Item key="show">
                        <ShowButton title="Просмотр" size="small" recordItemId={record.id}
                                    className="action-table-button">
                          Просмотр
                        </ShowButton>
                      </Menu.Item>
                      <Menu.Item key="isBlocked">
                        <BanUserButton
                          section="clients"
                          id={record?.id}
                          isBlocked={record.isBlocked}
                          isGhost={true}
                          onSuccess={
                            () => tableQueryResult.refetch()
                          }
                        />
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Icons.MoreOutlined className={"table-dropdown-icon"}/>
                </Dropdown>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Row>
  )
}
