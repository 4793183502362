import React, {FC, ReactNode} from 'react';
import {IPerformer} from '../../../common/types';
import {Button, Descriptions} from '@pankod/refine-antd';
import {MEETING_PLACE_TYPE, MODERATE_STATUS} from '../../../common/constants';
import ModerateButtons from '../../../components/buttons/ModerateButtons';
import {FinanceButton} from '../../../components/buttons/FinanceButton';
import {LineChartOutlined} from '@ant-design/icons';
import {Link} from '@pankod/refine-react-router-v6';


export interface IPerformerInfoProps {
  title?: string
  performer?: IPerformer
  children?: ReactNode
  mt?: number
}

const PerformerInfo: FC<IPerformerInfoProps> = ({performer, title, children, mt}) => {
  return (
    <Descriptions
      style={{ marginTop: mt ?? 0 }}
      column={1}
      bordered={true}
      title={title}
      layout="vertical"
      size="small"
    >
      <Descriptions.Item label="ФИО">
        {performer?.name || '-'}
      </Descriptions.Item>
  
      <Descriptions.Item label="Никнейм">
        {performer?.nickname || '-'}
      </Descriptions.Item>
  
      <Descriptions.Item label="Телефон">
        {performer?.phone || '-'}
      </Descriptions.Item>
    
      <Descriptions.Item label="Прошел модерацию">
        {performer?.isModerated ? 'Да' : 'Нет'}
      </Descriptions.Item>
    
      <Descriptions.Item label="Статус модерации">
        {MODERATE_STATUS.find(ms => ms.key === performer?.moderationStatus)?.name || '-'}
      </Descriptions.Item>
    
      <Descriptions.Item label="Статус">
        {performer?.isBlocked ? 'Заблокирован' : 'Активный'}
      </Descriptions.Item>
  
      <Descriptions.Item label="Рейтинг">
        {performer?.rating?.value || '-'}
      </Descriptions.Item>

      {children}
      
      <Descriptions.Item label="Контакты для администратора">
        <p>Инстаграм: {performer?.contactsForAdmin?.instagram || '-'}</p>
        <p>Профиль на сайте: {performer?.contactsForAdmin?.profileOnWebsites || '-'}</p>
        <p>Телеграм (аккаунт): {performer?.contactsForAdmin?.telegramAccount || '-'}</p>
        <p>Телеграм (канал): {performer?.contactsForAdmin?.telegramChannel || '-'}</p>
      </Descriptions.Item>
    </Descriptions>
  )
}

export default PerformerInfo
