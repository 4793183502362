import React from 'react';
import Loader from './Loader';

export const LoaderScreen = () => {
  return (
    <div className="loader-wrapper">
      <Loader />
    </div>
  )
}
