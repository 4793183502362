import {useLogin, useTranslate} from "@pankod/refine-core";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
} from "@pankod/refine-antd";
import React, {FC, useEffect} from "react";
import {ILoginForm} from '../../common/types';
import {FORM_REQUIRED_RULE} from '../../common/constants';
import {setDocumentTitle} from '../../common/functions';

const {Text, Title} = Typography;

export const LoginPage: FC = () => {
  const [form] = Form.useForm<ILoginForm>()
  const {mutate: login} = useLogin<ILoginForm>()

  useEffect(() => {
    setDocumentTitle('Авторизация')
  }, [])

  const CardTitle = (
    <Title level={3} style={{paddingTop: 10}}>
      Войти
    </Title>
  )

  return (
    <AntdLayout
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%),url('images/login-bg.png')",
        backgroundSize: "cover",
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div style={{maxWidth: "408px", margin: "auto"}}>
            <Card title={CardTitle} bordered={false}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={login}
                requiredMark={false}
                initialValues={{
                  remember: false,
                  username: '',
                  password: '',
                }}
              >
                <Form.Item
                  name="username"
                  label="Логин"
                  rules={FORM_REQUIRED_RULE}
                >
                  <Input
                    size="large"
                    placeholder="Email"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Пароль"
                  rules={FORM_REQUIRED_RULE}
                >
                  <Input.Password
                    visibilityToggle={true}
                    type="password"
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                >
                  <Checkbox
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Запомнить меня
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  noStyle={true}
                >
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                  >
                    Войти
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
