import React, {FC, useEffect} from 'react';
import {Button, Form, Input} from '@pankod/refine-antd';
import {Select, Space} from 'antd';
import {IFilter} from '../../common/types';
import {setInitialFilters} from '../../common/functions';
import {Filter} from './Filter';


export const UserFilter: FC<IFilter> = ({formProps, children, filters, setClearFilters}) => {
  useEffect(() => setInitialFilters(formProps, filters), [])

  return (
    <Filter formProps={formProps} setClearFilters={setClearFilters} filters={filters}>
      <Form.Item
        label="Название"
        name="search"
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Статус"
        name="isBlocked"
      >
        <Select
          placeholder="Не выбран"
          allowClear={true}
          onClear={() => formProps.form?.submit()}
        >
          <Select.Option key="true">
            Заблокирован
          </Select.Option>
          <Select.Option key="false">
            Активен
          </Select.Option>
        </Select>
      </Form.Item>
        <Form.Item label='Статус элита' name='moderationStatusElite'>
            <Select
                placeholder="Не выбран"
                    allowClear={true}
                    onClear={() => formProps.form?.submit()}
            >
                <Select.Option key="SENT">
                    На рассмотрении
                </Select.Option>
                <Select.Option key="ACCEPTED">
                    Да
                </Select.Option>
                <Select.Option key="NOTMODERATED">
                    Нет
                </Select.Option>
            </Select>
        </Form.Item>
      {children}

    </Filter>
  )
}
