import React from "react";
import { TitleProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import {settings} from '../../../common/settings';

const { Link } = routerProvider

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    <h1 className="logo">{collapsed ? settings.project.name[0] : settings.project.name}</h1>
  </Link>
)
