import { FormProps } from "@pankod/refine-antd";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { CrudFilters } from "@pankod/refine-core";
import { CrudSorting } from "@refinedev/core";
import { Moment } from "moment/moment";
import { TableProps } from "antd";

export enum EAppearanceCategory {
  IMAGE = "IMAGE",
  COSPLAY = "COSPLAY",
  ROLE = "ROLE",
}

export enum EServiceType {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
  APPEARANCE = "APPEARANCE",
  TOY = "TOY",
}

export enum EMeetingPlaceType {
  NOTSET = "NOTSET",
  APARTMENTS = "APARTMENTS",
  SALON = "SALON",
}

export enum EModerationStatus {
  NOTMODERATED = "NOTMODERATED",
  SENT = "SENT",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
}

export enum EUserType {
  CLIENT = "CLIENT",
  ADVERTISER = "ADVERTISER",
  SALON = "SALON",
  INTERNALUSER = "INTERNALUSER",
}

export enum EOperationType {
  REFILL = "REFILL",
  CONVERSION = "CONVERSION",
  WITHDRAW = "WITHDRAW",
  REFUND = "REFUND",
  CASHOUT = "CASHOUT",
}

export enum EStatus {
  PENDING = "PENDING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export interface IParams {
  [index: string]: string;
}

export interface IBanButtonProps {
  id?: string;
  isBlocked: boolean;
  isGhost?: boolean;
  onSuccess: () => void;
  section: "salons" | "clients" | "performers";
}

export interface IModerateButtonProps {
  id?: string;
  isAccept: boolean;
  onSuccess: () => void;
}

export interface IModerateEliteButtonProps {
  id?: string;
  isAccept: boolean;
  onSuccess: () => void;
  eliteStatus: any;
}

export interface ISalonContact {
  id: string;
  phone: string;
  email: string;
  address: string;
  createdAt: string;
}

export interface ISalon {
  id: string;
  name: string;
  description: string;
  workTime: string;
  siteUrl: string;
  timezone: string;
  contactDetails: ISalonContact;
  isBlocked: boolean;
  createdAt: string;
  balance?: number;
}

export interface IBalance {
  amount: number;
  balance: number;
  createdAt: string;
  nickname: string;
  type: EBalanceType;
  userId: string;
  userType: string;
  percentage: number;
  info: string;
}

export interface IProfit {
  id: string;
  advertiser: IPerformer;
  client: IClient;
  amount: number;
  type: string;
  userType: string;
  percentage: number;
  conclusion: boolean;
  createdAt: string;
}

export enum ECryptoType {
  REFILL = "REFILL",
  WITHDRAWAL = "WITHDRAWAL",
  CONSOLIDATION = "CONSOLIDATION",
  COMMISSION = "COMMISSION",
}

export const CRYPRO_TYPE: {
  [key in keyof typeof ECryptoType]: string;
} = {
  REFILL: "Пополнение",
  WITHDRAWAL: "Вывод",
  CONSOLIDATION: "Слияние",
  COMMISSION: "Комиссионный",
};

export enum ECryptoStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  PENDING = "PENDING",
}

export const CRYPRO_STATUS: {
  [key in keyof typeof ECryptoStatus]: string;
} = {
  SUCCESS: "Успешно",
  ERROR: "Ошибка",
  PENDING: "В ожидании",
};

export enum EProfitType {
  BOOST = "BOOST",
  WITHDRAWAL = "WITHDRAWAL",
  SHIFT = "SHIFT",
  COMMISSIONS = "COMMISSIONS",
  SEANCE_FEE = "SEANCE_FEE",
}

export const PROFIT_TYPE: {
  [key in keyof typeof EProfitType]: string;
} = {
  BOOST: "Буст",
  WITHDRAWAL: "Комиссия за вывод",
  SHIFT: "Смена",
  COMMISSIONS: "Комиссия за сеанс",
  SEANCE_FEE: "Сбор за сеанс",
};

export enum EBalanceType {
  payment = "payment",
  withdrawal = "withdrawal",
  shift = "shift",
  boost_buy = "boost_buy",
  withdrawal_commission = "withdrawal_commission",
  refill = "refill",
  payout = "payout",
  seance_fee = "seance_fee",
  seance_commission = "seance_commission",
  return = "return",
}

export const BALANCE_TYPE: {
  [key in keyof typeof EBalanceType]: string;
} = {
  payment: "Оплата",
  withdrawal: "Вывод",
  shift: "Смена",
  boost_buy: "Буст",
  withdrawal_commission: "Комиссия за вывод",
  refill: "Пополнение",
  payout: "Выплата",
  seance_fee: "Сбор за сеанс",
  seance_commission: "Комиссия за сеанс",
  return: "Возврат",
};

export enum EDisputeStatus {
  CLIENTFAVOR = "CLIENTFAVOR",
  ADVERTISERFAVOR = "ADVERTISERFAVOR",
  NEW = "NEW",
}

export const DISPUTE_TYPE: {
  [key in keyof typeof EDisputeStatus]: string;
} = {
  CLIENTFAVOR: "Решен в пользу клиента",
  ADVERTISERFAVOR: "Решен в пользу сотрудника",
  NEW: "На рассмотрении",
};

export interface ICommission {
  id: string;
  type: string;
  commission: number;
}

export interface ILanguage {
  RU: string;
  EN: string;
}

export interface IParamName {
  id: string;
  name: ILanguage;
}

export interface IParamDescription extends Omit<IParamName, "name"> {
  description: ILanguage;
}

export interface IParamRange extends Omit<IParamName, "name"> {
  min: number;
  max: number;
}

export interface ILocation {
  id: string;
  name: any;
  createdAt: string;
}

export interface IRating {
  id: string;
  value: string;
  status: string;
}

export interface IClient {
  id: string;
  nickname: string;
  name: string | null;
  phone: string | null;
  physique: IParamName | null;
  gender: IParamName | null;
  weightRange: IParamRange | null;
  ageRange: IParamRange | null;
  ethnos: IParamName | null;
  country: ILocation | null;
  region: ILocation | null;
  city: ILocation | null;
  district: ILocation | null;
  underground: ILocation | null;
  timezone: string;
  isBlocked: boolean;
  createdAt: string;
  rating: IRating | null;
  numberOfRatings: number;
  referral?: number;
  balance?: number;
}

export interface ICount {
  ended: number;
  active: number;
  all: number;
}
export interface IPhotoFile {
  id: string;
  file: IFile;
  isBlur: boolean;
}

export interface IFile {
  id: string;
  urls: {
    100?: string;
    300?: string;
    500?: string;
    1000?: string;
    1500?: string;
    2000?: string;
    3000?: string;
    4000?: string;
  };
  url: string;
  width: number | null;
  height: number | null;
  fileName: string;
  contentType: string;
}

export interface IPerformerAddress {
  id: string;
  address: string;
  type: "ACCURATE" | "INACCURATE";
  apartsNumber: string;
  entrance: string;
  accuracy: number;
  location: number[];
  createdAt: string;
}

export enum EMeetingType {
  AT_ME = "AT_ME",
  VISIT = "VISIT",
  BOTH = "BOTH",
}

export interface IMeetingPlace {
  id: string;
  meetingPlace: IParamDescription;
  placeType: EMeetingPlaceType;
  strangersInApartment: boolean;
  visitToOtherCountry: boolean;
  visitToOtherCity: boolean;
  visitToApartment: boolean;
  visitToHotel: boolean;
  visitToSauna: boolean;
  visitToOffice: boolean;
  meetingInCar: boolean;
  advertiserAddress: IPerformerAddress;
  meetingType: EMeetingType;
}

export interface IContactAdmin {
  id: string;
  profileOnWebsites: string;
  instagram: string;
  telegramChannel: string;
  telegramAccount: string;
}

export interface IVideoVerification {
  id: string;
  code: string;
  video: IFile;
  isVerified: boolean;
  dispatchedAt: string;
  createdAt: string;
}

export interface ILanguageLevel {
  id: string;
  language: string;
  level: IParamName;
}

export interface IEthnicRestriction {
  id: string;
  ethhos: IParamName;
  hideProfile: boolean;
  disableAutoBooking: boolean;
}

export interface IPerformer {
  id: string;
  phone: string | null;
  isBlocked: boolean;
  nickname: string;
  name: string | null;
  nameEn: string | null;
  descriptionRu: string | null;
  descriptionEn: string | null;
  address: string | null;
  addressDescription: string | null;
  visitingRulesRu: string | null;
  visitingRulesEn: string | null;
  minClientAge: number | null;
  maxClientAge: number | null;
  convenientTimeToChat: string | null;
  breastSize: number | null;
  siliconeBreast: boolean | null;
  penisLengthCm: number | null;
  penisGirthCm: number | null;
  heightCm: number | null;
  weightKg: number | null;
  piercing: boolean | null;
  tattoos: boolean | null;
  isModerated: boolean | null;
  physique: IParamName;
  gender: IParamName;
  serviceType: IParamName | null;
  smokingAttitude: IParamDescription | null;
  alcoholAttitude: IParamDescription | null;
  eyeColor: IParamName | null;
  ethnos: IParamName | null;
  ageRange: IParamRange | null;
  hairColor: IParamName | null;
  intimateHaircut: IParamDescription | null;
  timezone: string | null;
  rating: IRating | null;
  subscription?: string | null;
  numberOfRatings: number | null;
  salon?: ISalon | null;
  meetingPlace?: IMeetingPlace;
  contactsForAdmin?: IContactAdmin;
  languageLevels?: ILanguageLevel;
  ethnicRestrictions?: IEthnicRestriction;
  balance?: number;
  createdAt: string;
  photoWithBlurFace: IFile | null;
  photoWithoutBlurFace: IFile | null;
  videoVerification?: IVideoVerification | null;
  moderationStatus: EModerationStatus;
  moderationStatusElite: EModerationStatus;
  isElite: boolean;
}

export interface IFilter {
  formProps: FormProps;
  children?: ReactNode;
  filters?: CrudFilters;
  setClearFilters?: Dispatch<SetStateAction<boolean>>;
}

export interface ICategory {
  id: string;
  name: ILanguage;
  uniqueKey: string;
  directions: IParamName[];
  createdAt: string;
}

export interface IServiceParam {
  id: string;
  name: ILanguage;
  category: ICategory;
  group: IParamName;
  advertiserGender: IParamName;
  clientGender: IParamName;
  direction: IParamName;
  createdAt: string;
}

export interface IService {
  id: string;
  nameRu: string;
  nameEn: string;
  descriptionRu: string;
  descriptionEn: string;
  priceRub: number;
  priceUsd: number;
  preparationDuration: number;
  bySympathy: boolean;
  type: EServiceType;
  appearanceCategory: "IMAGE" | "COSPLAY" | "ROLE";
  category: ICategory;
  cover: IFile;
  service: IServiceParam;
  direction: IParamName;
  createdAt: string;
  tariffId?: string;
}

export interface IKeyName<T> {
  key: T;
  name: string;
}

export interface ITariff {
  id: string;
  nameRu: string;
  nameEn: string;
  descriptionRu: string;
  descriptionEn: string;
  priceRub: number;
  priceUsd: number;
  cumCount: number;
  duration: number;
  preparationDuration: number;
  type: EServiceType;
  cover: IFile;
  tariff: {
    id: string;
    name: ILanguage;
    duration: number;
    icon: string;
    createdAt: string;
  };
  meetingPlace: IParamDescription;
  services: IService[];
  createdAt: string;
}

export enum EOrderStatus {
  AUTOCANCELED = "AUTOCANCELED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  PAID = "PAID",
  NEW = "NEW",
  CANCELED = "CANCELED",
  DISPUTED = "DISPUTED",
}

export interface ITimeslot {
  id: string;
  fromTime: string;
  toTime: string;
  isBooked: boolean;
}

export interface ITariffTimeslot {
  id: string;
  priceRub: string;
  priceUsd: string;
  tariff: ITariff;
}

export interface IServiceTimeslot extends Omit<ITariffTimeslot, "tariff"> {
  service: IService;
}

export interface IScheduleTemplate {
  id: string;
  name: string;
  isRoundTheClock: true;
  fromTime: string;
  toTime: string;
  timebreaks: string[];
  locality: {
    id: string;
    address: string;
    location: number[];
    accuracy: number;
  };
  meetingPlace: IMeetingPlace;
  tariffs: ITariffTimeslot[];
  services: IServiceTimeslot[];
}

export interface IPerformerTimeslot {
  id: string;
  date: string;
  scheduleTemplate: IScheduleTemplate;
  timeslots: ITimeslot[];
}

export interface IOrder {
  id: string;
  tariffs?: ITariff[];
  services?: IService[];
  offers?: [
    {
      id: string;
      comment: string;
      priceRub: number;
      priceUsd: number;
      createdAt: string;
    }
  ];
  offersCount?: number;
  payment?: {
    id: string;
    status: "PENDING" | "ERROR" | "SUCCESS";
    wallet: {
      id: string;
      address: string;
    };
    createdAt: string;
  } | null;
  costRub: number | string;
  costUsd?: number | string;
  timeslot?: {
    id: string;
    fromTime: string;
    toTime: string;
    isBooked: boolean;
    createdAt: string;
  };
  timeslotFrom?: string;
  timeslotTo?: string;
  status: EOrderStatus;
  cancellationReason?: string | null;
  advertiser?: IPerformer;
  client?: IClient;
  isExternal: boolean;
  salon?: ISalon | null;
  createdAt?: string;
  tariffsCount?: number;
  servicesCount?: number;
  cumsCount?: number;
  schedule?: IPerformerTimeslot;
  isCanceledByAdvertiser?: boolean;
  seance?: any;
}

export interface IPerformerData {
  data: IPerformer;
  images: IPhotoFile[];
  videos: IFile[];
  services: IService[];
  tariffs: ITariff[];
}

export interface ISalonData {
  data: ISalon;
  performers: IPerformer[];
}

export interface IClientData {
  data: IClient;
}

export interface IServiceCardProps {
  services: IService[];
  span?: number;
  isShort?: boolean;
}

export interface ITariffModalProps {
  selectedTariff: ITariff | null;
  setSelectedTariff: Dispatch<SetStateAction<ITariff | null>>;
}

export interface IStatistic {
  amount: string;
  count: number;
  date: string;
}

export interface IFinanceFiltersData {
  performers: IPerformer[];
  salons: ISalon[];
}

export interface IOrderData extends IFinanceFiltersData {
  clients: IClient[];
  orders: IOrder[];
}

export interface IOrderFilter extends IFilter {
  performers?: IPerformer[];
  salons?: ISalon[];
  clients?: IClient[];
}

export interface IUserSupportFilter extends IFilter {
  counts?: {
    data: { ended: number; active: number; all: number };
  };
}

export interface ITariffWithCount extends ITariff {
  count: number;
}

export interface IData<T> {
  data: T;
}

export interface IMeta {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export interface IResponse<T> {
  data: {
    data: T;
    meta: IMeta;
  };
}

export interface ITransaction {
  id: string;
  type: EOperationType;
  status: EStatus;
  amount: string;
  userType: EUserType;
  createdAt: string;
}

export interface ICurrencyRate {
  rates: {
    DAI: {
      RUB: number;
      USD: number;
    };
    USDT: {
      RUB: number;
      USD: number;
    };
  };
}

export interface IResolution {
  id: string;
  refundAmount: string;
  forbidClientFeedback: boolean;
  forbidAdvertiserFeedback: boolean;
  createdAt: string;
}

export interface IDispute {
  id: string;
  status: EDisputeStatus;
  text: string;
  advertiser: IPerformer;
  client: IClient;
  orderNo: number;
  resolution: IResolution | null;
  createdAt: string;
  seanceId: string;
  orderId: string;
  seanceCost: number;
  review: string;
  rating: number;
}

export interface IBlacklist {
  id: string;
  phone: string;
  createdAt: string;
  comment?: string;
}

export interface ISupportChat {
  advertiser: { nickname: string };
  status: string;
  roomId: string;
}

export interface IBlackListForm {
  phone: string;
  comment?: string | null;
}

export interface IOrderFilter {
  clientId?: string;
  advertiserId?: string;
  salonId?: string;
  timeslotFrom?: Moment[];
  date?: Moment[];
}

export interface IPerformerTableProps {
  tableProps: TableProps<IPerformer>;
  sorter?: CrudSorting;
  withActions?: boolean;
}

export interface ISettings {
  costBoost: number;
  costShift: number;
  feeSeance: number;
  percentCommon: number;
  percentElite: number;
}

export interface IChangeConditionsForm {
  costBoost?: number | null;
  costShift?: number | null;
  feeSeance?: number | null;
  percentCommon?: number | null;
  percentElite?: number | null;
  password: string;
}

export interface IChangeCommissionForm {
  address?: string | null;
  password: string;
}

export interface ICrypto {
  createdAt: string;
  from: string;
  nickname: string;
  sum: string;
  to: string;
  txnHash: string;
  type: string;
  status: string;
  userId: string;
  userType: string;
}

export interface ICryptoWallet {
  id: string;
  wallet: string;
}

export interface IChangeCryptoWallet {
  wallet: string | null;
  password: string;
}
